import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { add, isSameDay, sub } from 'date-fns';
import * as _ from 'lodash';
import {
  AppUser,
  CalendarEventData,
  Specification,
  TemplateTrainingDay,
  WeightEntryData,
  convertFormToModel,
  durationFromSessionMaster,
} from 'src/app/core/thecoach';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { SpecificationsService } from 'src/app/services/specifications.service';
import { WeightlogService } from 'src/app/services/weightlog.service';

@Component({
  selector: 'app-calendar-full-event-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div (click)="toggleModal()" class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative w-full md:w-3/6 transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6"
          >
            <div *ngIf="selectedWeightEntry">
              <div class="flex flex-row justify-center items-center">
                <div
                  class="flex h-8 w-8 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 stroke-teal-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </div>
                <h3
                  class="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                  *ngIf="
                    selectedCalendarEventEntries?.length === 0 || isDateClick
                  "
                >
                  {{ selectedWeightEntry.date | date: 'dd-MM-yyyy' }}
                </h3>
                <h3
                  class="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                  *ngIf="
                    selectedCalendarEventEntries &&
                    selectedCalendarEventEntries.length > 0 &&
                    !isDateClick
                  "
                >
                  {{
                    selectedCalendarEventEntries[0].start | date: 'dd-MM-yyyy'
                  }}
                  -
                  {{ selectedCalendarEventEntries[0].end | date: 'dd-MM-yyyy' }}
                </h3>
              </div>
              <div class="my-2 space-y-4 text-center sm:my-2">
                <app-modal-weight-entry
                  [selectedWeightEntry]="selectedWeightEntry"
                  *ngIf="user.enableWeightLog"
                  (deleteWeightEntry)="onDeleteWeightEntryModal($event)"
                  (showWeightEntryModal)="onShowWeightEntryModal($event)"
                ></app-modal-weight-entry>

                <app-modal-macros
                  *ngIf="user.enableMacroTracking"
                  [selectedWeightEntry]="selectedWeightEntry"
                  [user]="user"
                  [toggleGraphUpdater]="toggleGraphUpdater"
                  (showMacrosModal)="onShowMacrosModal($event)"
                  (deleteMacrosModal)="onDeleteMacrosModal($event)"
                ></app-modal-macros>

                <app-modal-ctm
                  *ngIf="user.enableCTMs"
                  [selectedWeightEntry]="selectedWeightEntry"
                  [user]="user"
                  (deleteCTMModal)="onDeleteCTMModal($event)"
                  (showCTMModal)="onShowCustomsModal($event)"
                >
                </app-modal-ctm>
                <div class="mt-2" *ngIf="selectedTrainingDay">
                  <div class="text-xs">
                    <div class="mt-2 flex items-start space-x-3 text-xs">
                      <dt class="mt-0.5">
                        <div
                          class="mx-auto flex h-3 w-3 items-start justify-start rounded-full"
                        >
                          <img src="../../assets/icons/dumbbell.png" />
                        </div>
                      </dt>
                      <dd class="flex justify-start flex-col">
                        <p>
                          Trainingday: {{ selectedTrainingDay.trainingDayName }}
                        </p>
                        <p>
                          {{ selectedTrainingDay.sessionRating }}/10 -
                          {{ getDurationFromSession(selectedTrainingDay) }}
                        </p>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-modal-event
              [selectedDate]="selectedWeightEntry.date"
              [selectedCalendarEventEntries]="selectedCalendarEventEntries"
              (selectedEvent)="onCalEventSelector($event)"
            ></app-modal-event>

            <app-modal-coach-specs
              [coach]="coach"
              [selectedSpecEntries]="selectedSpecEntries"
              [selectedDate]="selectedWeightEntry.date"
              (selectedSpecEvent)="onShowSpecModal($event)"
            ></app-modal-coach-specs>
            <div class="mt-5 sm:mt-6">
              <button
                type="button"
                (click)="toggleModal()"
                class="inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Go back to Calendar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-coach-specsentry-modal
      *ngIf="showSpecEntryMenu && selectedSpecEntries"
      [selectedSpecEntry]="selectedSpecEntry!"
      [client]="user"
      (cancel)="onCancel($event)"
      (onSaveData)="onSaveSpec($event)"
    ></app-coach-specsentry-modal>

    <div
      *ngIf="showWeightEntryMenu"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-weight-entry
        (onClick)="onClickEvent($event)"
        (onSaveData)="onSaveDataToServer($event)"
        @AnimationTrigger1
        *ngIf="showWeightEntryMenu"
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
        [day]="this.selectedWeightEntry.date"
        [weightEntry]="this.selectedWeightEntry"
      ></app-weight-entry>
    </div>

    <div
      *ngIf="macrosModalToggle"
      id="modal-background"
      @AnimationTrigger0
      class="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
    >
      <app-macro-entry-modal
        class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
        (onSaveData)="onSaveDataToServer($event)"
        (onClick)="toggleMacrosModal($event)"
        [day]="selectedWeightEntry.date"
        [weightEntry]="selectedWeightEntry"
      >
      </app-macro-entry-modal>
    </div>

    <div
      *ngIf="
        user.ctmId &&
          selectedWeightEntry &&
          !selectedWeightEntry.customTrackingLog &&
          (this.ctmService.getModelByID(user) | async) as customTrackingModel;
        else editModel
      "
    >
      <app-custom-model-modal
        *ngIf="customModalToggle"
        [ctm]="customTrackingModel"
        (close)="onCloseTrackingModal($event.event, $event.form, user)"
      >
      </app-custom-model-modal>
    </div>

    <ng-template #editModel>
      <app-custom-model-modal
        *ngIf="customModalToggle"
        [ctm]="selectedWeightEntry!.customTrackingLog"
        (close)="onCloseTrackingModal($event.event, $event.form, user)"
      >
      </app-custom-model-modal>
    </ng-template>
    <div *ngIf="newCalendarEventToggler && selectedWeightEntry.date">
      <app-calendar-new-entry-modal
        [inputDate]="selectedWeightEntry.date"
        [eventEntry]="selectedEventData"
        (close)="newCalendarEventToggle($event)"
        (save)="onSaveCalendarEvent($event)"
      ></app-calendar-new-entry-modal>
    </div>

    <app-coach-specsentry-modal
      *ngIf="newSpecEventToggler && selectedWeightEntry.date"
      class="fixed inset-0 z-50 overflow-y-auto bg-white h-fit m-auto w-5/6 lg:w-2/6 rounded-lg"
      [selectedSpecEntry]="selectedSpecEntry!"
      [client]="user"
      (cancel)="onCancel($event)"
      (onSaveData)="onSaveSpec($event)"
    ></app-coach-specsentry-modal>

    <div *ngIf="deleteEventToggler">
      <app-simple-modal
        [title]="'Delete Event entry?'"
        [message]="'Do you want to delete this event?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteModal($event)"
      ></app-simple-modal>
    </div>

    <div *ngIf="deleteWeightEntryToggler">
      <app-simple-modal
        [title]="'Delete Weightentry?'"
        [message]="
          'Do you want to delete this event?' +
          '<br>' +
          'Caution! This will also delete Macro and Trackinginfo!'
        "
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteWeightEntry($event)"
      ></app-simple-modal>
    </div>

    <div *ngIf="deleteMacrosToggler">
      <app-simple-modal
        [title]="'Delete Macros entry?'"
        [message]="'Do you want to delete the Macros entry?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteMacros($event)"
      ></app-simple-modal>
    </div>

    <div *ngIf="deleteCTMToggler">
      <app-simple-modal
        [title]="'Delete Custom Tracking entry?'"
        [message]="'Do you want to delete the Custom Tracking entry?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="deleteCTMModal($event)"
      ></app-simple-modal>
    </div>

    <div *ngIf="deleteSpecToggler">
      <app-simple-modal
        [title]="'Delete Specifications entry?'"
        [message]="'Do you want to delete the Specification entry?'"
        [positiveButtonText]="'Yes'"
        [negativeButtonText]="'No'"
        (close)="onDeleteSpecModalConfim($event)"
      ></app-simple-modal>
    </div>
    <div *ngIf="savingToggler">
      <app-loader-modal
        [loadingText]="'Saving Calendardata...'"
      ></app-loader-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
  ],
})
export class CalendarFullEventModalComponent implements OnInit {
  @Input() user!: AppUser;
  @Input() coach?: AppUser;
  @Input() selectedSpecEntries?: Specification[];
  @Input() selectedWeightEntry!: WeightEntryData;
  @Input() selectedTrainingDay?: TemplateTrainingDay;
  @Input() selectedCalendarEventEntries?: CalendarEventData[];
  @Input() isDateClick!: boolean;
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<WeightEntryData>();
  isModal = true;

  selectedEventData: CalendarEventData | undefined;

  showWeightEntryMenu = false;
  macrosModalToggle = false;
  toggleGraphUpdater = false;
  customModalToggle = false;
  newCalendarEventToggler = false;
  showSpecEntryMenu = false;
  newSpecEventToggler = false;

  deleteEventToggler = false;
  deleteWeightEntryToggler = false;
  deleteMacrosToggler = false;
  deleteCTMToggler = false;
  deleteSpecToggler = false;

  isLoaded = false;

  selectedSpecEntry: Specification | undefined;
  savingToggler: boolean = false;
  constructor(
    protected ctmService: CustomTrackingModelService,
    private weightLogService: WeightlogService,
    private calendarService: CalendarService,
    private specsService: SpecificationsService,
  ) {
    //console.log('STARTING COMPONENT')
  }
  ngOnInit(): void {
    if (!this.selectedCalendarEventEntries) {
      this.newCalendarEventToggler = !this.newCalendarEventToggler;
    }

    // console.log('DATECLICL', this.isDateClick)

    // console.log('inputEvents', this.selectedCalendarEventEntries)
    if (!this.selectedSpecEntries) {
      this.newSpecEventToggler = !this.newSpecEventToggler;
    }
    //  console.log('inputspecs', this.selectedSpecEntries)
    //  console.log("opening...", this.selectedWeightEntry);
  }

  toggleSave() {
    this.savingToggler = !this.savingToggler;
  }

  updateGraph() {
    this.toggleGraphUpdater = !this.toggleGraphUpdater;
  }

  toggleModal() {
    this.isModal = !this.isModal;
    if (this.selectedCalendarEventEntries) {
      //console.log('ADDING')
      this.selectedCalendarEventEntries.forEach((entry) => {
        entry.end = add(entry.end as Date, { days: 1 });
      });
    }

    if (this.selectedSpecEntries)
      this.selectedSpecEntries.forEach((entry) => {
        (entry.isEdit = true),
          (entry.end = add(entry.end as Date, { days: 1 }));
      });

    this.onClose.emit(true);
  }

  toggleClose() {
    this.isModal = !this.isModal;
    if (this.selectedCalendarEventEntries)
      this.selectedCalendarEventEntries.forEach((entry) => {
        entry.end = add(entry.end as Date, { days: 1 });
      });

    if (this.selectedSpecEntries)
      this.selectedSpecEntries.forEach((entry) => {
        (entry.isEdit = true),
          (entry.end = add(entry.end as Date, { days: 1 }));
      });

    this.onClose.emit(true);
  }

  toggleOnSave() {
    this.onSave.emit(this.selectedWeightEntry);
  }

  toggleWeightEntryMenu() {
    this.showWeightEntryMenu = !this.showWeightEntryMenu;
  }

  toggleSpecEntryMenu() {
    this.showSpecEntryMenu = !this.showSpecEntryMenu;
  }

  onShowWeightEntryModal(event: boolean) {
    if (event) this.toggleWeightEntryMenu();
  }

  onShowMacrosModal(event: boolean) {
    if (event) this.toggleMacrosModal();
  }

  toggleMacrosModal(event?: boolean) {
    this.macrosModalToggle = !this.macrosModalToggle;
  }

  onShowCustomsModal(event: boolean) {
    if (event) this.toggleCustomsEditMenu();
  }

  toggleCustomsEditMenu() {
    this.customModalToggle = !this.customModalToggle;
  }

  onClickEvent(event: boolean) {
    this.toggleWeightEntryMenu();
  }

  onCloseTrackingModal(
    event: boolean,
    form: FormGroup | undefined,
    user: AppUser,
  ) {
    this.customModalToggle = !this.customModalToggle;
    if (event) {
      const ctModel = convertFormToModel(form as FormGroup);
      if (this.selectedWeightEntry) {
        this.toggleSave();
        this.selectedWeightEntry.customTrackingLog = ctModel;

        this.weightLogService
          .addSpecificParametersToLog(
            user.id as string,
            this.selectedWeightEntry,
          )
          .then(() => {
            this.toggleSave();
          })
          .catch((error) => {
            console.error(error);
            this.toggleSave();
          });
      }
    }
  }

  getDurationFromSession(td: TemplateTrainingDay) {
    return durationFromSessionMaster(td);
  }

  onSaveDataToServer(data: WeightEntryData) {
    this.toggleSave();
    this.selectedWeightEntry.weight = data.weight;
    this.selectedWeightEntry.comment = data.comment;

    this.weightLogService
      .addDataToLogForUser(
        this.selectedWeightEntry.date as Date,
        this.user,
        this.selectedWeightEntry,
      )
      .then(() => {
        this.toggleSave();
      })
      .catch((error) => {
        console.error(error);
        this.toggleSave();
      });
    this.updateGraph();
    if (this.macrosModalToggle) this.toggleMacrosModal();
    if (this.showWeightEntryMenu) this.toggleWeightEntryMenu();
  }

  onCalEventSelector(eventData: {
    mode: boolean;
    data: CalendarEventData | undefined;
  }) {
    if (eventData.mode) {
      this.newCalendarEventToggle(eventData.data);
    } else {
      if (eventData.data) this.deleteEvent(eventData.data);
    }
  }

  onShowSpecModal(eventData: {
    mode: boolean;
    data: Specification | undefined;
  }) {
    if (eventData.mode) {
      this.newSpecEventToggle(eventData.data);
    } else {
      if (eventData.data) this.deleteSpecEvent(eventData.data);
    }
  }

  deleteSpecEvent(event: Specification) {
    if (event) this.selectedSpecEntry = event;
    this.deleteSpecToggler = !this.deleteSpecToggler;
  }

  newCalendarEventToggle(event?: CalendarEventData) {
    this.newCalendarEventToggler = !this.newCalendarEventToggler;
    if (event) this.selectedEventData = event;
  }

  newSpecEventToggle(event?: Specification) {
    this.newSpecEventToggler = !this.newSpecEventToggler;
    if (event) this.selectedSpecEntry = event;
  }

  deleteEvent(event: CalendarEventData) {
    if (event) this.selectedEventData = event;
    this.deleteEventToggler = !this.deleteEventToggler;
  }

  onSaveCalendarEvent(event: CalendarEventData) {
    if (event) {
      this.toggleSave();
      if (!event.id) {
        this.calendarService
          .saveNewCalendarEntryForUser(event, this.user)
          .then(() => {
            this.toggleSave();
          })
          .catch((error) => {
            console.error(error);
            this.toggleSave();
          });
        this.selectedCalendarEventEntries?.push(event);
      } else {
        this.calendarService
          .updateCalendarEntryForUser(event, this.user)
          .then(() => {
            this.toggleSave();
          })
          .catch((error) => {
            console.error(error);
            this.toggleSave();
          });
      }
      //this.inputEventData = event;
    }
  }

  onDeleteModal(event: boolean) {
    if (event && this.selectedEventData && this.selectedCalendarEventEntries) {
      //console.log(this.selectedEventData, this.selectedCalendarEventEntries)
      this.calendarService.deleteCalendarEntryForUser(
        this.selectedEventData,
        this.user,
      );

      this.selectedCalendarEventEntries = this.removeItemById(
        this.selectedCalendarEventEntries,
        this.selectedEventData.id as string,
      );
    }
    this.deleteEventToggler = !this.deleteEventToggler;
  }

  onDeleteSpecModalConfim(event: boolean) {
    if (
      event &&
      this.coach &&
      this.user &&
      this.selectedSpecEntry &&
      this.selectedSpecEntries
    ) {
      // console.log('Before', this.selectedSpecEntries)

      this.specsService
        .deleteSpecification(
          this.coach.id as string,
          this.user.id as string,
          this.selectedSpecEntry,
        )
        .then(() => {
          //    console.log('spec deleted!')
        })
        .catch((e: Error) => {
          //   console.log('Error', e)
        });

      this.selectedSpecEntries = this.removeItemByIdForSpec(
        this.selectedSpecEntries,
        this.selectedSpecEntry.id as string,
      );

      // console.log('AFTER', this.selectedSpecEntries)
    }
    this.deleteSpecToggler = !this.deleteSpecToggler;
  }

  onDeleteWeightEntryModal(event: boolean) {
    if (event) this.deleteWeightEntryToggler = !this.deleteWeightEntryToggler;
  }

  onDeleteWeightEntry(event: boolean) {
    if (event) {
      this.weightLogService.deleteDataPointForUser(
        this.selectedWeightEntry.date as Date,
        this.user,
      );
      const savedDate = this.selectedWeightEntry.date;
      this.selectedWeightEntry = { date: savedDate };
    }

    this.deleteWeightEntryToggler = !this.deleteWeightEntryToggler;
  }

  onDeleteMacrosModal(event: boolean) {
    if (event) this.deleteMacrosToggler = !this.deleteMacrosToggler;
  }

  onDeleteSpecModal(event: boolean) {
    if (event) this.deleteSpecToggler = !this.deleteSpecToggler;
  }

  onDeleteMacros(event: boolean) {
    if (event) {
      delete this.selectedWeightEntry.achivedMacros;
      this.weightLogService.overWriteLogForUser(
        this.selectedWeightEntry,
        this.user,
      );
    }

    this.deleteMacrosToggler = !this.deleteMacrosToggler;
  }

  onDeleteCTMModal(event: boolean) {
    if (event) {
      this.deleteCTMToggler = !this.deleteCTMToggler;
    }
  }

  deleteCTMModal(event: boolean) {
    if (event) {
      delete this.selectedWeightEntry.customTrackingLog;
      this.weightLogService.overWriteLogForUser(
        this.selectedWeightEntry,
        this.user,
      );
    }

    this.deleteCTMToggler = !this.deleteCTMToggler;
  }
  removeItemById(
    array: CalendarEventData[],
    idToRemove: string,
  ): CalendarEventData[] {
    return array.filter((item) => item.id !== idToRemove);
  }
  removeItemByIdForSpec(
    array: Specification[],
    idToRemove: string,
  ): Specification[] {
    return array.filter((item) => item.id !== idToRemove);
  }
  removeItemByIdUniveral(array: { id: string }[], idToRemove: string): {}[] {
    return array.filter((item) => item.id !== idToRemove);
  }

  onCancel(event: boolean) {
    this.newSpecEventToggler = !this.newSpecEventToggler;
  }

  onSaveSpec(event: Specification) {
    //console.log('got following spec', event)

    if (event && this.coach && this.user) {
      this.toggleSave();
      if (!event.id) {
        this.specsService
          .addSpecification(
            this.coach.id as string,
            this.user.id as string,
            event,
          )
          .then(() => {
            this.toggleSave();
          })
          .catch((error) => {
            console.error(error);
            this.toggleSave();
          });
        this.selectedSpecEntries?.push(event);
      } else {
        this.specsService
          .updateSpecification(
            this.coach.id as string,
            this.user.id as string,
            event,
          )
          .then(() => {
            this.toggleSave();
          })
          .catch((error) => {
            console.error(error);
            this.toggleSave();
          });
      }
      //this.inputEventData = event;
    }

    //this.toggleSpecEntryMenu();
    this.newSpecEventToggler = !this.newSpecEventToggler;
  }
}
