import { Location } from '@angular/common';
import {
  MesoCycle,
  TemplateTrainingDay,
  TemplateTrainingSlot,
  TrainingLog,
  convertDateObject,
} from './../../core/thecoach';
import { Subscription, filter, first, last, tap } from 'rxjs';
import { TrainingplanService } from './../../services/trainingplan.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TrainingPlan, Exercise } from 'src/app/core/thecoach';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseService } from 'src/app/services/exercise.service';
import { LogService } from 'src/app/services/log.service';
import { Timestamp } from 'firebase/firestore';
import { intervalToDuration, isToday, parse, parseISO } from 'date-fns';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-customer-trainingplans',
  template: `
    <div class="overscroll-none">
      <div *ngIf="this.selectedMeso">
        <div class="flex justify-center">
          <span
            class="flex w-full justify-between text-center -space-x-px   bg-white "
          >
            <div
              class="flex w-full justify-between"
              *ngIf="!tdDropdownToggler; else ToLongNames"
            >
              <button
                id="trainingDayBTN"
                (click)="onLoadTrainingday(td)"
                *ngFor="let td of this.selectedMeso.trainingDays"
                class="inline-block text-center border rounded-md border-gray-300 shadow-sm whitespace-nowrap px-2  py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative"
                [ngClass]="{ 'bg-gray-200': selectedTrainingDay.id === td.id }"
              >
                {{ td.trainingDayName }}
              </button>
            </div>

            <ng-template #ToLongNames>
              <div class="flex w-full flex-col justify-center items-center">
                <label
                  id="listbox-label"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Select Trainingday</label
                >
                <div class="relative">
                  <button
                    type="button"
                    (click)="toggleSelect()"
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                  >
                    <span class="block text-sm truncate">{{
                      firstTrainingday?.trainingDayName
                    }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>

                  <ul
                    @opacityLeave
                    *ngIf="isSelect"
                    class="absolute z-40 mt-1 max-h-60 w-fit  rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-3"
                  >
                    <li
                      *ngFor="let td of this.selectedMeso.trainingDays"
                      (click)="onLoadTrainingday(td)"
                      class="text-gray-900  text-center  z-50 relative cursor-default select-none py-2 px-4"
                      role="option"
                    >
                      <!-- Selected: "font-semibold", Not Selected: "font-normal" -->

                      <span class="text-sm">
                        {{ td.trainingDayName }}
                      </span>
                    </li>

                    <!-- More items... -->
                  </ul>
                </div>
              </div>
            </ng-template>
          </span>
        </div>
      </div>

      <div
        *ngIf="
          this.selectedTrainingDay && (this.selectedTrainingDay | json) != '{}'
        "
      >
        <div class="p-4 space-y-2">
          <h3
            *ngIf="(this.selectedSlot | json) == '{}'; else selectedExercise"
            class="text-base font-semibold text-center"
          >
            Select Exercise
          </h3>
          <ng-template #selectedExercise>
            <div class="flex justify-center flex-row items-center">
              <h3 class="text-base font-semibold text-center pr-1">
                {{ this.selectedSlot.exerciseName }}
              </h3>
            </div>
          </ng-template>
          <div class="flex space-x-3 justify-center">
            <div
              (click)="onLoadSlot(slot, i)"
              *ngFor="
                let slot of this.selectedTrainingDay.trainingSlots;
                let i = index
              "
              class="w-12 h-5 rounded-sm bg-gray-600"
              [ngClass]="{
                'bg-teal-500': i === activeSlotIndex,
              }"
            ></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="(this.selectedSlot | json) != '{}'"
        class="flex justify-center"
      ></div>
      <div
        *ngIf="
          this.selectedSlot &&
          (this.selectedSlot | json) != '{}' &&
          (this.selectedTrainingDay | json) != '{}'
        "
      >
        <app-slot-input
          [slot]="this.selectedSlot"
          [slotAmount]="this.selectedTrainingDay.trainingSlots!.length"
          [lastSessionSlot]="this.lastLoggedSlot"
          [exerciseDescription]="this.selectedSlot.exerciseDescription"
          (save)="saveSlot()"
          (back)="backOneSlot()"
          (saveWorkoutlog)="onSaveWorkoutToLog($event)"
          (cancelCurrentWorkout)="onCancelCurrentWorkout()"
          (emitKeyDown)="onSaveToLocalStorage($event)"
        ></app-slot-input>
      </div>

      <div
        *ngIf="
          (this.activeSlotIndex === 0 &&
            this.selectedTrainingDay.trainingSlots!.length > 1) ||
          (this.activeSlotIndex &&
            this.activeSlotIndex <
              this.selectedTrainingDay.trainingSlots!.length - 1)
        "
        class="pt-2 flex flex-col justify-center text-center"
      >
        <h3 class="text-sm font-semibold text-center">Up Next:</h3>
        <p class="text-xs italic">
          {{
            this.selectedTrainingDay.trainingSlots![this.activeSlotIndex + 1]
              .exerciseName
          }}
          -
          {{
            this.selectedTrainingDay.trainingSlots![this.activeSlotIndex + 1]
              .sets
          }}
          x
          {{
            this.selectedTrainingDay.trainingSlots![this.activeSlotIndex + 1]
              .reps
          }}
        </p>
      </div>

      <hr
        *ngIf="this.activeSlotIndex || this.activeSlotIndex === 0"
        class="mx-8 my-2"
      />

      <div
        *ngIf="(this.selectedTrainingDay | json) != '{}'"
        class=" flex flex-col justify-center text-center"
      >
        <app-timer></app-timer>

        <div
          class="flex flex-row justify-center overflow-hidden rounded-md bg-white px-4 py-2 shadow ring-1 ring-gray-300"
          (click)="onShowSessionOverview()"
        >
          <h3 class="text-base font-semibold text-center">Session Overview</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="pl-2 w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
            />
          </svg>
        </div>
        <div
          *ngIf="!showSessionOverview"
          class="mt-1 text-sm italic flex flex-col justify-start overflow-hidden rounded-md bg-white px-4 py-2 shadow"
        >
          <div *ngFor="let slot of this.selectedTrainingDay.trainingSlots">
            <p class="flex flex-row w-full">
              {{ slot.exerciseName }} - {{ slot.sets }} x {{ slot.reps }}
            </p>
          </div>
        </div>
      </div>

      <hr *ngIf="(this.logPlan | json) != '{}'" class="mx-8 my-2" />

      <div *ngIf="(this.logPlan | json) != '{}'" class="">
        <div
          class="flex flex-row justify-center overflow-hidden rounded-md bg-white px-4 py-2 shadow"
          (click)="onShowLoggedSessions()"
        >
          <h3 class="text-base font-semibold text-center">Last Sessions</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 pl-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
        </div>
        <div *ngIf="this.showloggedSessions" class="pt-2">
          <div class="pb-2">
            <nav
              class="isolate flex divide-x divide-gray-200 rounded-lg shadow pb-2"
              aria-label="Tabs"
            >
              <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
              <div
                class="text-gray-900 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white px-4 text-center text-sm font-medium hover:bg-teal-50 focus:z-10"
                (click)="onToggleShowLogs(true)"
              >
                <span>Meso</span>
              </div>

              <div
                class="text-gray-500 hover:text-gray-700 group relative min-w-0 flex-1 overflow-hidden bg-white  px-4 text-center text-sm font-medium hover:bg-teal-50 focus:z-10"
                (click)="onToggleShowLogs(false)"
              >
                <span>All Logs</span>
              </div>
            </nav>
          </div>

          <div>
            <ul
              role="list"
              class="space-y-3"
              *ngIf="this.loggedSessions.length > 0; else noDataAvailable"
            >
              <ng-container *ngIf="!showAllLogData; else showAllData">
                <li
                  *ngFor="let session of loggedSessions; let i = index"
                  class="overflow-hidden rounded-md bg-white px-4 py-2 shadow"
                  (click)="toggleSession(i)"
                >
                  <!-- Your content -->

                  <div class="w-full justify-between flex flex-col">
                    <div class="w-full justify-between flex flex-row">
                      <h3 class="font-semibold text-xs italic flex flex-row">
                        {{ session.startDate | date: 'dd-MM-yyyy' }} |
                        {{ session.startDate | date: 'HH:mm' }}
                        <span *ngIf="session.endDate"
                          >&#160;-&#160;{{ session.endDate | date: 'HH:mm' }}
                        </span>
                      </h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-gray-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                        />
                      </svg>
                    </div>
                    <div class="w-full justify-start flex flex-row">
                      <h3 class="font-semibold text-xs italic flex flex-row">
                        {{ session.trainingDayName }}
                      </h3>
                      <div
                        *ngIf="session.endDate && session.startDate"
                        class="pl-1"
                      >
                        <h3 class="font-semibold text-xs italic flex flex-row">
                          |
                          {{ getDuration(session.endDate, session.startDate) }}
                          |&#160;
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="i === activePastSessions" class="flex flex-col">
                    <div *ngFor="let slot of session.trainingSlots">
                      <div *ngIf="slot.inputSets" class="py-1">
                        <p class="text-xs italic font-semibold">
                          {{ slot.exerciseName }}
                        </p>
                        <div *ngFor="let inputSet of slot.inputSets">
                          <p class="text-xs italic">
                            {{ inputSet.setnr! + 1 }} Set -
                            {{ inputSet.weight }}kg x
                            {{ inputSet.reps }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between w-full col-span-2">
                      <button
                        (click)="onDeleteSession(session)"
                        type="button"
                        class="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        Delete
                      </button>

                      <button
                        (click)="reloadPastSession(session)"
                        type="button"
                        class="rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                      >
                        Edit Session
                      </button>
                    </div>
                  </div>
                </li>
              </ng-container>
              <ng-template #showAllData>
                <div *ngFor="let meso of this.logPlan.mesoCycle; let j = index">
                  <li
                    *ngFor="let session of meso.trainingDays; let i = index"
                    class="overflow-hidden rounded-md bg-white px-4 py-2 shadow"
                    (click)="toggleSessionInMeso(j, i)"
                  >
                    <!-- Your content -->

                    <div class="w-full justify-between flex flex-col">
                      <div class="w-full justify-between flex flex-row">
                        <h3
                          class="font-semibold text-xs italic flex flex-row"
                          *ngIf="session.startDate"
                        >
                          {{
                            convertAnyKindOfDate(session.startDate)
                              | date: 'dd-MM-yyyy'
                          }}
                          |
                          {{
                            convertAnyKindOfDate(session.startDate)
                              | date: 'HH:mm'
                          }}
                          <span *ngIf="session.endDate"
                            >&#160;-&#160;{{
                              convertAnyKindOfDate(session.endDate)
                                | date: 'HH:mm'
                            }}
                          </span>
                        </h3>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-600"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                          />
                        </svg>
                      </div>
                      <div class="w-full justify-start flex flex-row">
                        <h3 class="font-semibold text-xs italic flex flex-row">
                          {{ meso.mesoname }} | {{ session.trainingDayName }}
                        </h3>
                        <div
                          *ngIf="session.endDate && session.startDate"
                          class="pl-1"
                        >
                          <h3
                            class="font-semibold text-xs italic flex flex-row"
                          >
                            |
                            {{
                              getDuration(session.endDate, session.startDate)
                            }}
                            |&#160;
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="j === activePastMeso && i === activePastSessions"
                      class="flex flex-col"
                    >
                      <div *ngFor="let slot of session.trainingSlots">
                        <div *ngIf="slot.inputSets" class="py-1">
                          <p class="text-xs italic font-semibold">
                            {{ slot.exerciseName }}
                          </p>
                          <div *ngFor="let inputSet of slot.inputSets">
                            <p class="text-xs italic">
                              {{ inputSet.setnr! + 1 }} Set -
                              {{ inputSet.weight }}kg x
                              {{ inputSet.reps }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="flex justify-between w-full col-span-2">
                        <button
                          (click)="onDeleteSession(session)"
                          type="button"
                          class="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        >
                          Delete
                        </button>

                        <button
                          (click)="reloadPastSession(session)"
                          type="button"
                          class="rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        >
                          Edit Session
                        </button>
                      </div>
                    </div>
                  </li>
                </div>
              </ng-template>
              <!-- More items... -->
            </ul>
          </div>
          <ng-template #noDataAvailable>
            <div
              class="w-full text-sm justify-center text-center italic text-red-500"
            >
              <p>No Logs available for this Meso</p>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="pt-4 flex justify-center flex-col" *ngIf="this.selectedMeso">
        <button
          (click)="toggleTPScheme()"
          type="button"
          class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Show Trainingplan Schedule
        </button>

        <div *ngIf="tpSchemeToggler">
          <ul
            role="list"
            class="space-y-3 pt-2 flex flex-col justify-center items-center"
          >
            <li
              class="overflow-hidden rounded-md bg-white px-6 py-4 shadow w-full"
              *ngFor="let td of this.selectedMeso.trainingDays"
            >
              <div>
                <h3 class="font-bold text-sm">
                  Session: {{ td.trainingDayName }}
                </h3>
              </div>

              <div>
                <ul role="list ">
                  <li
                    *ngFor="let slot of td.trainingSlots"
                    class="text-xs italic"
                  >
                    {{ slot.exerciseName }} - {{ slot.sets }} x {{ slot.reps }}
                  </li>
                </ul>
              </div>
            </li>

            <!-- More items... -->
          </ul>
        </div>
      </div>

      <div class="pt-8" *ngIf="this.trainingplan">
        <footer class="fixed inset-x-0 bottom-0 h-fit">
          <div class="flex space-x-4 overflow-x-auto bg-teal-800">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <button
              (click)="onLoadMeso(meso, k)"
              *ngFor="let meso of this.trainingplan.mesoCycles; let k = index"
              [disabled]="
                (k !== activeMesoIndex && activeSlotIndex !== undefined) || null
              "
              class="bg-gray-900 text-white  disabled:opacity-25 px-3 py-2 text-sm font-medium cursor-pointer"
              aria-current="page"
              [ngClass]="{
                'bg-teal-600 text-black': k === activeMesoIndex,
              }"
            >
              {{ meso.mesoname }}
            </button>
          </div>
        </footer>
      </div>

      <div>
        <app-simple-modal
          *ngIf="newSessionToggle"
          (close)="onCreateNewTrainingDay($event)"
          [positiveButtonText]="'Yes'"
          [negativeButtonText]="'Cancel'"
          [title]="'Start Session'"
          [message]="'Do you want to start a new session?'"
        >
          ></app-simple-modal
        >
      </div>
      <div>
        <app-simple-modal
          *ngIf="reloadSessionsToggle"
          (close)="reloadSession($event)"
          [positiveButtonText]="'Yes'"
          [negativeButtonText]="'Cancel'"
          [title]="'Reload Session'"
          [message]="'Do you want to edit a past session?'"
        >
          ></app-simple-modal
        >
      </div>
      <div>
        <app-simple-modal
          *ngIf="loadSessionsToggle"
          (close)="loadSession($event)"
          [positiveButtonText]="'New Session'"
          [negativeButtonText]="'Edit Session'"
          [title]="'Edit or New Session?'"
          [message]="'You allready have a session with todays date!'"
        >
          ></app-simple-modal
        >
      </div>

      <div>
        <app-simple-modal
          *ngIf="deleteSessionsToggle"
          (close)="deleteSession($event)"
          [positiveButtonText]="'Yes'"
          [negativeButtonText]="'No'"
          [title]="'Delete Session?'"
          [message]="'Do you want to delete this session?'"
        >
          ></app-simple-modal
        >
      </div>

      <div>
        <app-simple-modal
          *ngIf="emptySessionSaveToggle"
          (close)="emptySaveSession($event)"
          [positiveButtonText]="'Yes'"
          [negativeButtonText]="'No'"
          [title]="'Session is unfinished?'"
          [message]="'Do you want to save this unfinished session?'"
        >
          ></app-simple-modal
        >
      </div>

      <div>
        <app-simple-modal
          *ngIf="cancelCurrentWorkoutToggle"
          (close)="onCancelCurrentWorkoutModal($event)"
          [positiveButtonText]="'Yes'"
          [negativeButtonText]="'No'"
          [title]="'Cancel current Workout?'"
          [message]="'Do you want to cancel the current Workout??'"
        >
          ></app-simple-modal
        >
      </div>
    </div>

    <div>
      <app-session-rating-modal
        *ngIf="sessionRatingModalToggle"
        [session]="this.selectedTrainingDay"
        [title]="'Session is finished!'"
        (close)="saveSessionAfterRating($event)"
      >
        ></app-session-rating-modal
      >
    </div>
    <div *ngIf="savingToggler">
      <app-loader-modal [loadingText]="'Saving Traininglog'"></app-loader-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CustomerTrainingplansComponent implements OnInit, OnDestroy {
  trainingPlanSubscription: Subscription | undefined;
  exerciseSubscription: Subscription | undefined;
  logSubscription: Subscription | undefined;

  id: string = '';
  trainingplan: TrainingPlan = {};
  selectedMeso: MesoCycle = {};

  selectedTrainingDay: TemplateTrainingDay = {};
  tempTrainingDay: TemplateTrainingDay = {};
  selectedSlot: TemplateTrainingSlot = {};
  exercises: Exercise[] = [];
  newSessionToggle = false;

  activeSlotIndex: number | undefined;
  logPlan: TrainingLog = {};
  loggedSessions: TemplateTrainingDay[] = [];

  reloadSessionsToggle: boolean = false;
  temporarySession: TemplateTrainingDay = {};

  loadSessionsToggle: boolean = false;
  activePastSessions: number | undefined;
  activePastMeso: number | undefined;

  deleteSessionsToggle: boolean = false;
  deleteSessionId: string | undefined;

  emptySessionSaveToggle: boolean = false;

  activeMesoIndex: number | undefined;

  lastLoggedSession: TemplateTrainingDay = {};
  lastLoggedSlot: TemplateTrainingDay = {};

  showloggedSessions = false;
  showSessionOverview = false;
  run = true;

  cancelCurrentWorkoutToggle = false;
  sessionRatingModalToggle = false;
  showAllLogData = false;

  tpSchemeToggler = false;

  tdDropdownToggler = false;
  isSelect = false;
  firstTrainingday: TemplateTrainingDay | undefined = undefined;

  savingToggler = false;

  constructor(
    private tpService: TrainingplanService,
    private route: ActivatedRoute,

    private logService: LogService,
    private db: AngularFirestore,
    private router: Router,
  ) {
    this.checkBack();
  }
  ngOnDestroy(): void {
    this.trainingPlanSubscription?.unsubscribe();
    this.logSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') as string;

    if (this.id) {
      this.trainingPlanSubscription = this.tpService
        .getTrainingPlan(this.id)
        .pipe(
          first(),
          filter((trainingPlan) => !!trainingPlan),
          tap((trainingPlan) => {
            this.trainingplan = trainingPlan as TrainingPlan;

            if (this.trainingplan && this.trainingplan.mesoCycles) {
              this.selectedMeso =
                this.trainingplan.mesoCycles[
                  this.trainingplan.mesoCycles?.length - 1
                ];
              this.activeMesoIndex = this.trainingplan.mesoCycles?.length - 1;

              let totalLength = 0;
              this.selectedMeso.trainingDays?.forEach((td) => {
                if (td && td.trainingDayName) {
                  totalLength += td.trainingDayName?.length * 6.5 + 32;
                }
              });
              totalLength = Math.floor(totalLength);

              if (
                totalLength >= window.innerWidth &&
                this.selectedMeso &&
                this.selectedMeso.trainingDays
              ) {
                this.tdDropdownToggler = true;
                this.firstTrainingday = this.selectedMeso.trainingDays[0];
              }
            }

            if (this.trainingplan) this.getLog(this.trainingplan.id!);
            //console.log(this.trainingplan)
            if (localStorage.getItem('session') !== null) {
              this.loadLocalStorageSession();
            }
          }),
        )
        .subscribe();
    }
  }

  toggleTPScheme() {
    this.tpSchemeToggler = !this.tpSchemeToggler;
  }
  onSaveToLocalStorage(event: boolean) {
    if (event) {
      this.updateSlot(this.selectedTrainingDay, this.selectedSlot);

      this.saveSlotToLocalStorage(this.selectedTrainingDay);
    }
  }

  loadLocalStorageSession() {
    this.selectedTrainingDay = JSON.parse(
      localStorage.getItem('session') as string,
    );
    // console.log('parsing Date', this.selectedTrainingDay.startDate);

    this.selectedTrainingDay.startDate = convertDateObject(
      this.selectedTrainingDay.startDate!,
    );

    if (this.selectedTrainingDay.endDate) {
      this.selectedTrainingDay.endDate = convertDateObject(
        this.selectedTrainingDay.endDate,
      );
    }

    //console.log('converted Date', this.selectedTrainingDay.startDate);

    let index = this.selectedTrainingDay.trainingSlots?.findIndex((element) => {
      if (element.inputSets) {
        return element.inputSets?.some((inputSet) => {
          return inputSet.weight === undefined || inputSet.reps === undefined;
        });
      } else {
        return element;
      }
    });

    this.onLoadSlot(
      this.selectedTrainingDay.trainingSlots!.at(
        index!,
      ) as TemplateTrainingSlot,
      index!,
    );
    this.activeSlotIndex = index;
    this.getLog(this.selectedTrainingDay.trainingPlanId as string);
  }

  onToggleShowLogs(value: boolean) {
    if (value) {
      this.showAllLogData = false;
      this.loggedSessions = this.getTrainingDaysFromLog(
        this.logPlan,
        this.selectedMeso,
      ) as TemplateTrainingDay[];

      this.loggedSessions = this.convertDateSessionDates(this.loggedSessions);
    } else {
      this.showAllLogData = true;
      this.loggedSessions = [];

      this.logPlan.mesoCycle?.sort((a, b) => {
        //Bitwise  truncate a number to its integer part - faster than math.floor or math.ceil
        const mesoA = ~~(a.mesoname?.replace(/^\D+/g, '') ?? 0);
        const mesoB = ~~(b.mesoname?.replace(/^\D+/g, '') ?? 0);

        return mesoB - mesoA;
      });

      this.logPlan.mesoCycle?.forEach((meso) => {
        meso.trainingDays?.forEach((td) => {
          this.loggedSessions.push(td);
        });
        this.loggedSessions = this.convertDateSessionDates(this.loggedSessions);
      });
    }
  }

  convertDateSessionDates(td: TemplateTrainingDay[]) {
    td.forEach((td) => {
      if (td.startDate) td.startDate = convertDateObject(td.startDate);
      if (td.endDate) td.endDate = convertDateObject(td.endDate);
    });

    return td;
  }

  onShowSessionOverview() {
    this.showSessionOverview = !this.showSessionOverview;
  }

  onShowLoggedSessions() {
    this.showloggedSessions = !this.showloggedSessions;
    this.onToggleShowLogs(true);
  }

  checkBack() {
    history.pushState(null, '', location.href);
    //console.log(this.activeSlotIndex);
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.state) {
          if (this.activeSlotIndex || this.activeSlotIndex === 0) {
            if (this.activeSlotIndex > 0) {
              this.activeSlotIndex = this.activeSlotIndex - 1;
              this.selectedSlot =
                this.selectedTrainingDay.trainingSlots![this.activeSlotIndex];
              history.go(1);
            } else {
              this.router.navigate(['/trainingplans']).then(() => {
                window.location.reload();
              });
            }
          } else {
            this.router.navigate(['/trainingplans']).then(() => {
              window.location.reload();
            });
          }
        }
      },
      false,
    );
  }

  convertAnyKindOfDate(date: Date) {
    return convertDateObject(date);
  }

  getDuration(endDate: Date, startDate: Date) {
    startDate = convertDateObject(startDate) as Date;
    endDate = convertDateObject(endDate) as Date;

    let inter = intervalToDuration({ start: startDate, end: endDate });

    if (
      inter &&
      inter.hours !== undefined &&
      inter.hours < 1 &&
      inter.minutes !== undefined &&
      inter.minutes < 1
    )
      return inter.seconds + 's';

    if (inter && inter.hours !== undefined && inter.hours < 1)
      return inter.minutes + 'min ';

    return inter.hours + 'h ' + inter.minutes + 'min ';
  }

  getLog(trainingplanId: string) {
    this.logSubscription = this.logService
      .getLog(trainingplanId)
      .subscribe((data) => {
        if (data) {
          this.logPlan = data as TrainingLog;

          this.logPlan.mesoCycle?.reverse();

          let tempTd = this.getTrainingDaysFromLog(
            this.logPlan,
            this.selectedMeso,
          );
          if (tempTd) {
            this.loggedSessions = tempTd;

            this.loggedSessions.forEach((session) => {
              if (session.startDate) {
                session.startDate = convertDateObject(session.startDate);
              }

              if (session.endDate) {
                session.endDate = convertDateObject(session.endDate);
              }
            });

            //this.loggedSessions.reverse();
            this.loggedSessions.sort(
              (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
            );
            this.getTrainingDayFromLastLoggedSession();
            if (
              localStorage.getItem('session') !== null &&
              !this.activeSlotIndex &&
              !this.selectedTrainingDay.trainingSlots
            ) {
              this.getSlotFromLastLoggedSession(
                this.selectedTrainingDay.trainingSlots!.at(
                  this.activeSlotIndex!,
                ) as TemplateTrainingSlot,
              );
            }
          }
        }
      });
  }

  onLoadMeso(meso: MesoCycle, index: number) {
    if (this.activeSlotIndex === undefined || null) {
      this.selectedMeso = meso;
      this.activeMesoIndex = index;
      this.selectedTrainingDay = {};
      this.selectedSlot = {};
      this.activePastSessions = undefined;
      this.activeSlotIndex = undefined;
      this.showloggedSessions = false;
      this.showSessionOverview = false;

      if (this.trainingplan) this.getLog(this.trainingplan.id!);
    }
  }
  onLoadTrainingday(td: TemplateTrainingDay) {
    this.toggleSelect();
    this.getLength();
    this.activePastSessions = undefined;
    this.activeSlotIndex = undefined;
    this.showloggedSessions = false;
    this.showSessionOverview = false;
    this.clearInputSolts(td);
    this.selectedSlot = {};
    this.tempTrainingDay = td;
    if (this.checkForExistingSession()) {
      this.loadSessionsToggle = true;
    } else {
      this.newSessionToggle = true;
    }
  }

  clearInputSolts(td: TemplateTrainingDay) {
    td.trainingSlots?.forEach((slot) => {
      if (slot.inputSets) delete slot.inputSets;
    });
  }

  onCreateNewTrainingDay(event: boolean) {
    this.newSessionToggle = false;

    if (event) {
      if (this.checkForExistingSession()) {
        this.loadSessionsToggle = true;
      } else {
        this.selectedTrainingDay = this.tempTrainingDay;
        this.selectedTrainingDay.id = this.db.createId();
        this.selectedTrainingDay.trainingPlanId = this.trainingplan.id;
        this.selectedTrainingDay.startDate = new Date();
        this.selectedTrainingDay.trainingDayId =
          this.tempTrainingDay.trainingDayId;
        this.showSessionOverview = true;
        this.firstTrainingday = this.tempTrainingDay;
      }
      this.getTrainingDayFromLastLoggedSession();
    }
  }

  loadSession(event: boolean) {
    this.loadSessionsToggle = false;
    this.activeSlotIndex = undefined;
    this.selectedTrainingDay = {};
    this.selectedSlot = {};
    this.lastLoggedSession = {};
    this.lastLoggedSlot = {};

    if (event) {
      this.selectedTrainingDay = this.tempTrainingDay;
      this.selectedTrainingDay.id = this.db.createId();
      this.selectedTrainingDay.startDate = new Date();
      this.showSessionOverview = true;
      this.firstTrainingday = this.tempTrainingDay;
    } else {
      this.selectedTrainingDay = this.getExistingSession();
      this.showSessionOverview = true;
      this.firstTrainingday = this.selectedTrainingDay;
    }
  }

  getExistingSession(): TemplateTrainingDay {
    let index = this.loggedSessions.findIndex((session) =>
      isToday(session.startDate!),
    );

    return this.loggedSessions[index];
  }

  checkForExistingSession(): boolean {
    return Boolean(
      this.loggedSessions.find(
        (session) =>
          isToday(session.startDate!) &&
          session.trainingDayName === this.tempTrainingDay.trainingDayName,
      ),
    );
  }

  onLoadSlot(slot: TemplateTrainingSlot, index: number) {
    if (!this.activeSlotIndex) {
      this.activePastSessions = undefined;
      this.selectedSlot = slot;
      this.activeSlotIndex = index;
      this.getSlotFromLastLoggedSession(this.selectedSlot);
    } else {
      //    this.lastLoggedSlot = this.selectedTrainingDay.trainingSlots![index];
      this.selectedSlot = this.selectedTrainingDay.trainingSlots![index];
      this.activeSlotIndex = index;
    }

    // this.updateSlot(this.selectedTrainingDay, this.selectedSlot);
    this.getSlotFromLastLoggedSession(this.selectedSlot);
    /*
    if (this.activeSlotIndex) {
      this.lastLoggedSlot = this.selectedTrainingDay.trainingSlots![index];
    }

 */
  }

  getSlotFromLastLoggedSession(slot: TemplateTrainingSlot) {
    //console.log(this.lastLoggedSlot, slot);
    if (this.lastLoggedSession) {
      let tempSlot = this.lastLoggedSession.trainingSlots?.find(
        (lastSlot) =>
          lastSlot.exerciseName === slot.exerciseName &&
          lastSlot.slot === slot.slot,
      );

      //console.log('tempslot', tempSlot);

      if (tempSlot) this.lastLoggedSlot = tempSlot;
    }
  }

  getTrainingDayFromLastLoggedSession() {
    if (this.loggedSessions) {
      let sameSessions = this.loggedSessions.filter(
        (session) =>
          session.trainingDayName === this.selectedTrainingDay.trainingDayName,
      );

      sameSessions.sort(
        (a, b) => b!.startDate!.getTime() - a!.startDate!.getTime(),
      );

      this.lastLoggedSession = sameSessions[0];
    }
  }

  loadNextSlot(index: number) {
    if (index < this.selectedTrainingDay.trainingSlots!.length - 1) {
      index = index + 1;
      this.selectedSlot = this.selectedTrainingDay.trainingSlots![index];
      this.activeSlotIndex = index;
    }
  }

  backOneSlot() {
    let index = this.activeSlotIndex;
    if (index !== undefined && index >= 1) {
      index = index - 1;
      //this.lastLoggedSlot = this.selectedTrainingDay.trainingSlots![index];
      this.selectedSlot = this.selectedTrainingDay.trainingSlots![index];
      this.activeSlotIndex = index;

      //  console.log('checking...', this.lastLoggedSlot);
      // this.updateSlot(this.selectedTrainingDay, this.selectedSlot);
    }
    this.getSlotFromLastLoggedSession(this.selectedSlot);
  }

  saveSlot() {
    this.updateSlot(this.selectedTrainingDay, this.selectedSlot);
    this.saveSlotToLocalStorage(this.selectedTrainingDay);

    this.loadNextSlot(this.activeSlotIndex!);
    // console.log(this.activeSlotIndex, this.lastLoggedSlot);
    this.getSlotFromLastLoggedSession(this.selectedSlot);
  }

  saveSlotToLocalStorage(trainingDay: TemplateTrainingDay) {
    localStorage.setItem('session', JSON.stringify(trainingDay));
  }

  onSaveWorkoutToLog(event: boolean) {
    this.updateSlot(this.selectedTrainingDay, this.selectedSlot);

    if (event) {
      if (!this.checkForEmptyInputSet()) {
        this.emptySessionSaveToggle = true;
      } else {
        this.sessionRatingModalToggle = true;
        // this.saveSession();
      }
    }
  }

  saveSessionAfterRating(event: boolean) {
    if (event) {
      this.saveSession();
    }
    this.sessionRatingModalToggle = !this.sessionRatingModalToggle;
  }

  clearEmptySlotsOfTrainingDay(td: TemplateTrainingDay) {
    td.trainingSlots?.forEach((slot) => {
      if (slot.inputSets) {
        slot.inputSets = slot.inputSets.filter((element) => {
          return Object.values(element).every(
            (value) => value === undefined || value === null,
          );
        });
        if (slot.inputSets.length === 0) {
          delete slot.inputSets;
        }
      }
    });
  }

  clearEmptySlotsOfSlot(ts: TemplateTrainingSlot) {
    if (ts.inputSets) {
      const emptyFieldsArray: boolean[] = [];

      const hasNonEmptyElements = ts.inputSets.some((element) => {
        const hasEmptyFields = Object.values(element).some(
          (value) => value !== undefined && value !== null,
        );

        emptyFieldsArray.push(hasEmptyFields);
      });

      if (
        !hasNonEmptyElements &&
        emptyFieldsArray.every((value) => value === false)
      ) {
        delete ts.inputSets;
      }
    }
  }

  saveSession() {
    this.savingToggler = true;
    localStorage.removeItem('session');

    // this.selectedTrainingDay.endDate = new Date();
    this.clearEmptySlotsOfSlot(this.selectedSlot);
    this.updateSlot(this.selectedTrainingDay, this.selectedSlot);
    this.logPlan.trainingPlanId = this.trainingplan.id;
    this.logPlan.clientId = this.trainingplan.clientId;
    this.logPlan.ownerId = this.trainingplan.ownerId;

    let mesoname = this.selectedMeso.mesoname;

    let indexMeso = this.logPlan.mesoCycle?.findIndex(
      (item) => item.mesoname === mesoname,
    );

    if (!this.logPlan.mesoCycle) this.logPlan.mesoCycle = [];

    if (indexMeso !== undefined) {
      if (indexMeso === -1) {
        let meso = { ...this.selectedMeso };
        meso.trainingDays = [];
        meso.trainingDays.push(this.selectedTrainingDay);
        this.logPlan.mesoCycle.push(meso);
        // console.log(this.logPlan.mesoCycle);
      } else {
        if (this.checkIfTrainingDayIdExists()) {
          this.updateTrainingDayInLog();
        } else {
          this.logPlan.mesoCycle
            ?.at(indexMeso)
            ?.trainingDays?.push(this.selectedTrainingDay);
        }
      }
    } else {
      this.logPlan.mesoCycle = [];
      let meso = { ...this.selectedMeso };
      meso.trainingDays = [];
      meso.trainingDays.push(this.selectedTrainingDay);
      this.logPlan.mesoCycle.push(meso);
    }

    this.logService.saveLog(this.logPlan).then(() => {
      this.selectedTrainingDay = {};
      this.tempTrainingDay = {};
      this.activeSlotIndex = undefined;

      this.selectedSlot = {};
      this.savingToggler = false;
    });
  }

  onCancelCurrentWorkout() {
    this.cancelCurrentWorkoutToggle = !this.cancelCurrentWorkoutToggle;
  }

  onCancelCurrentWorkoutModal(event: boolean) {
    if (event) {
      localStorage.removeItem('session');
      this.selectedTrainingDay = {};
      this.tempTrainingDay = {};
      this.activeSlotIndex = undefined;

      this.selectedSlot = {};
    }

    this.cancelCurrentWorkoutToggle = !this.cancelCurrentWorkoutToggle;
  }

  updateTrainingDayInLog() {
    let tempTd = this.getTrainingDaysFromLog(this.logPlan, this.selectedMeso);

    if (tempTd) {
      let index = tempTd.findIndex(
        (item) => item.id === this.selectedTrainingDay.id,
      );
      if (index) {
        tempTd[index] = this.selectedTrainingDay;
      }
    }
  }

  checkIfTrainingDayIdExists() {
    let tempTd = this.getTrainingDaysFromLog(this.logPlan, this.selectedMeso);

    if (tempTd) {
      return Boolean(
        tempTd.find((td) => td.id === this.selectedTrainingDay.id),
      );
    }

    return false;
  }

  updateSlot(td: TemplateTrainingDay, slot: TemplateTrainingSlot) {
    let index = this.tempTrainingDay.trainingSlots?.findIndex(
      (item) => item.slot === slot.slot,
    );

    if (index !== undefined) td.trainingSlots![index] = slot;
  }

  reloadPastSession(session: TemplateTrainingDay) {
    this.reloadSessionsToggle = true;
    this.temporarySession = session;
  }

  reloadSession(event: boolean) {
    this.reloadSessionsToggle = false;

    if (event) {
      this.selectedTrainingDay = this.temporarySession;
      this.selectedSlot = this.selectedTrainingDay.trainingSlots?.at(0)!;
      this.activeSlotIndex = 0;
    }
  }

  toggleSession(index: number) {
    if (this.activePastSessions === index) {
      this.activePastSessions = undefined;
    } else {
      this.activePastSessions = index;
    }
  }

  toggleSessionInMeso(meso: number, session: number) {
    if (this.activePastSessions === session) {
      this.activePastSessions = undefined;
      this.activePastMeso = undefined;
    } else {
      this.activePastSessions = session;
      this.activePastMeso = meso;
    }
  }

  onDeleteSession(session: TemplateTrainingDay) {
    this.deleteSessionsToggle = true;
    this.deleteSessionId = session.id;
  }

  deleteSession(event: boolean) {
    this.deleteSessionsToggle = false;

    if (event) {
      let tempTd = this.getTrainingDaysFromLog(this.logPlan, this.selectedMeso);

      if (tempTd) {
        let indexSession = tempTd.findIndex(
          (session) => session.id === this.deleteSessionId,
        );

        if (indexSession !== undefined) {
          tempTd.splice(indexSession, 1);

          this.logService.updateLog(this.logPlan);
          this.selectedTrainingDay = {};
          this.activeSlotIndex = undefined;
        }
      }
    }
  }

  getTrainingDaysFromLog(trainingLog: TrainingLog, selectedMeso: MesoCycle) {
    let indexMeso = trainingLog.mesoCycle?.findIndex(
      (item) => item.mesoname === selectedMeso.mesoname,
    );

    if (indexMeso !== undefined) {
      return trainingLog.mesoCycle?.at(indexMeso)?.trainingDays;
    }

    return undefined;
  }

  emptySaveSession(event: boolean) {
    this.emptySessionSaveToggle = false;
    if (event) {
      this.sessionRatingModalToggle = true;
      // this.saveSession();
    }
  }

  checkForEmptyInputSet() {
    let val = true;
    this.selectedTrainingDay.trainingSlots?.forEach((slot) => {
      if (!Array.isArray(slot.inputSets) || !slot.inputSets.length) {
        val = false;
      } else {
        if (slot.inputSets.length < Number(slot.sets)) val = false;

        slot.inputSets.forEach((set) => {
          if (!set.weight || !set.reps) val = false;
        });
      }
    });

    return val;
  }

  getLength() {
    const btn = document.getElementById('trainingDayBTN');
    // console.log(btn);
    if (btn) {
      //  console.log(btn.offsetWidth);
    }
  }

  toggleSelect() {
    this.isSelect = !this.isSelect;
    // console.log(this.isSelect);
  }
}
