import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserConfig } from '../../../../../backend/core/thecoach';
import { Subscription, first, of } from 'rxjs';
import { AppUser, UserRole } from 'src/app/core/thecoach';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-clients-overview',
  template: `
    <div
      *ngIf="showLoading"
      class="flex w-full mt-10 flex-row text-center justify-center"
    >
      <!-- Loading spinner or any loading indicator -->
      <div
        class="border-gray-300 h-6 w-6 animate-spin rounded-full border-4 border-t-teal-600"
      ></div>
      <p class="ml-2 text-md italic text-gray-700">Loading client data...</p>
    </div>
    <div
      [ngClass]="{
        'opacity-0 invisible': showLoading,
        'opacity-100 visible transition-opacity duration-500 ease-in':
          !showLoading,
      }"
    >
      <div
        class="mx-auto max-w-7xl sm:p-4"
        *ngIf="auth.appUser$ | async as appUser"
      >
        <div
          class="flex flex-col sm:flex-row w-full justify-between items-center"
        >
          <div class="overflow-hidden rounded-lg bg-white shadow h-fit">
            <div class="px-2 py-3 sm:p-3 border border-gray-200/40">
              <app-coach-payment-panel
                [activeUsers]="activeUsers"
                [user]="appUser"
                [userConfig]="userConfig"
              ></app-coach-payment-panel>
            </div>
          </div>
          <div class="overflow-hidden rounded-lg bg-white shadow h-fit">
            <div class="px-2 py-3 sm:p-3 border border-gray-200/40">
              <app-client-registration-panel
                [user]="appUser"
              ></app-client-registration-panel>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div
          class="flex flex-col sm:flex-row w-full justify-between items-center"
        >
          <div class="flex  rounded-lg bg-white shadow sm:mr-4 h-fit">
            <div class="px-2 py-3 sm:p-3 border border-gray-200/40">
              <app-client-pending-panel
                [userConfig]="userConfig"
                [user]="appUser"
                [activeUsers]="activeUsers"
              ></app-client-pending-panel>
            </div>
          </div>
          <div class="w-fit rounded-lg bg-white shadow">
            <div class="flex w-fit px-2 py-3 sm:p-3 border border-gray-200/40">
              <app-coaching-spot-panel
                [user]="appUser"
              ></app-coaching-spot-panel>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="flex flex-col sm:flex-row w-full justify-center">
          <div class="overflow-hidden rounded-lg bg-white shadow">
            <div class="flex flex-wrap justify-center w-full p-4 gap-4">
              <div *ngFor="let clientId of appUser.clientIds">
                <app-client-panel
                  (clients)="countActiveClient($event, appUser)"
                  [user]="appUser"
                  [clientId]="clientId"
                  [isCoach]="false"
                  [disableOnlyMode]="checkSpotQuantity()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="toggleNotification">
      <app-notification
        [messageHeader]="msgHeader"
        [messageBody]="msgBody"
        (onButtonClick)="tglNotification()"
      ></app-notification>
    </div>
  `,
  styles: [],
})
export class ClientsOverviewComponent implements OnInit, OnDestroy {
  userConfig: UserConfig | undefined = undefined;
  userConfigSubscription$: Subscription | undefined = undefined;
  activeUsers = new Map<string, AppUser>();
  toggleNotification = false;

  msgHeader: string = '';
  msgBody: string = '';

  showLoading = true;
  loadedClients = 0;

  constructor(
    protected auth: AuthService,
    private userSubscription: SubscriptionService,
    private userService: UserService,
  ) {}
  ngOnInit(): void {
    this.userConfigSubscription$ = this.userSubscription.userConfig$.subscribe(
      (userConfig) => {
        if (!userConfig) {
          this.showLoading = false;
          this.userConfig = undefined;
          return;
        }
        this.showLoading = false;
        this.userConfig = userConfig;
      },
    );
  }
  ngOnDestroy(): void {
    this.userConfigSubscription$?.unsubscribe();
  }

  countActiveClient(event: AppUser, coach: AppUser) {
    this.loadedClients++;

    if (event !== undefined) {
      this.activeUsers.set(event.id as string, event);

      this.activeUsers = new Map<string, AppUser>(
        [...this.activeUsers].filter(([key, value]) => value.enableWeightLog),
      );

      if (this.activeUsers && this.userConfig) {
        // Disable the client if the number of active users exceeds the allowed quantity
        const isClientActive = this.activeUsers.has(event.id as string);
        const exceededLimit = this.activeUsers.size > this.userConfig.quantity;

        if (isClientActive && exceededLimit) {
          //console.log('limit exceeded! Disabling,', event.displayName);
          this.disableClient(event as AppUser);
        }
      }
    }

    if (this.loadedClients === coach.clientIds?.length) {
      this.showLoading = false;
    }

    //console.log(this.userConfig?.quantity, this.activeUsers.size);
  }
  checkSpotQuantity() {
    if (this.activeUsers && this.userConfig) {
      return this.userConfig.quantity <= this.activeUsers.size;
    }
    return false;
  }

  disableClient(client: AppUser) {
    this.msgHeader = 'NOT ENOUGH SPOTS!';
    this.msgBody = 'Disabling Client: ' + client.email;
    this.toggleNotification = true;
    client.enableWeightLog = false;
    this.userService.saveUserData(client);
  }
  tglNotification() {
    this.msgHeader = '';
    this.msgBody = '';
    this.toggleNotification = !this.toggleNotification;
  }
}
