export const environment = {
  recaptcha: {
    sitekey: '6LdaOkUoAAAAABAAZqHng-r6GFM7OhknO_WVz23-',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCVRmeDkACIyp1wwKKomNyyh__mxb7jxHY',
    authDomain: 'thecoach-prod.firebaseapp.com',
    projectId: 'thecoach-prod',
    storageBucket: 'thecoach-prod.appspot.com',
    messagingSenderId: '162224508161',
    appId: '1:162224508161:web:19d1535dc035fd1a00df00',
    measurementId: 'G-SX91YXLFPM',
    vapidKey:
      'BEGlYBnJUSPkVo4DnZaPIxLf51ijQNyh-LW4Ji7BIsBaFx1eH96fjD_NhnVjdKF4B4zXaFsIujT_ivKtdGz5csA',
  },
  useEmulators: false,
  production: true,
  apiUrl: 'https://api-77rb4rjl3a-ew.a.run.app',
  version: '1.3.86',
};
