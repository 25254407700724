import { GeneratedNumberInputComponent } from './../common/generated-number-input/generated-number-input.component';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  NumberValueAccessor,
  Validators,
} from '@angular/forms';
import {
  differenceInYears,
  getDay,
  intervalToDuration,
  isDate,
  isSameDay,
  parse,
  parseISO,
} from 'date-fns';
import { User } from 'firebase/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { CheckinService } from '../services/checkin.service';
import { te } from 'date-fns/locale';
import { Ingredient } from '../../../../backend/node_modules/@prisma/client';
import { AddedIngredientWithIngredient } from '../../../../backend/core/thecoach';
import { Timestamp } from 'firebase/firestore';
import { StringNullableChain, isNaN, isNumber, parseInt } from 'lodash';
import { arrayReplaceAt } from 'markdown-it/lib/common/utils';

export class BaseModel {
  id?: string;
}

export class DietSheet extends BaseModel {
  clientName?: string;
  number?: number;
  date?: Date;
  weight?: number;
  steps?: number;
  comment?: string;
  normalDays?: Macros;
  refeedDays?: Macros;
  trackingDays?: Macros;
}

export class Macros extends BaseModel {
  protein?: number;
  carbs?: number;
  fat?: number;
}

//COACH TRAININGPLAN TEMPLATE
export class TemplateTrainingPlan extends BaseModel {
  //Full data model of a TrainingPlan model
  trainingPlanName?: string;
  trainingPlanDescription?: string;
  trainingDays?: TemplateTrainingDay[];
  ownerId?: string;
}

export class TemplateTrainingSlot extends BaseModel {
  //Slot generated from exercisese
  date?: Date;
  slot?: number;
  exerciseName?: string;
  exerciseDescription?: string;
  exerciseSetup?: string;
  exerciseVideoLink?: string;
  muscleGroup?: string;
  exerciseId?: string;
  comment?: string;
  sets?: string;
  reps?: string;
  rir?: string;
  inputSets?: InputSet[];
}

export class TrainingSlotEntry extends BaseModel {
  date?: Date;
  slot?: string;
  exerciseName?: string;
  exerciseId?: string;
  comment?: string;
  inputSets?: [{ setNr: number; weight: number; reps: number }];
}

export class InputSet extends BaseModel {
  setnr?: number;
  weight?: number;
  reps?: number;
}

export class Exercise extends BaseModel {
  exerciseName?: string;
  exerciseDescription?: string;
  exerciseVideoURL?: string;
  muscleGroup?: string;
  ownerId?: string;
}

export class TrainingLog extends BaseModel {
  trainingPlanId?: string;
  advancedView?: boolean;
  ownerId?: string;
  clientId?: string;
  mesoCycle?: MesoCycle[];
  lastEdit?: Date;
}

export class MesoCycle extends BaseModel {
  //Trainingcycle = Training week, or rythm
  mesoname?: string;
  trainingDays?: TemplateTrainingDay[];
}

export class TemplateTrainingDay extends BaseModel {
  //Trainingday generated form multiple TrainignSlots
  sessionRating?: number;
  startDate?: Date;
  endDate?: Date;
  trainingDayName?: string;
  trainingDayId?: string;
  trainingSlots?: TemplateTrainingSlot[];
  trainingPlanId?: string;
}
//For Client
export class TrainingPlan extends BaseModel {
  //Full data model of a TrainingPlan model
  trainingPlanName?: string;
  trainingPlanDescription?: string;
  mesoCycles?: MesoCycle[];
  clientId?: string;
  ownerId?: string;
  lastEdit?: Date;
}

//REDESIGN STARTED HERE

export class Specification extends BaseModel {
  title?: string;
  isEdit?: boolean;
  setMessage?: boolean;
  message?: string;
  setTrainingDay?: boolean;
  trainingDay?: string;
  setMacros?: boolean;
  specMacros?: Macros;
  start?: Date;
  end?: Date;
  allDay?: boolean;
}

export class WeightEntryData extends BaseModel {
  date?: Date;
  weight?: number;
  comment?: string;
  setMacros?: Macros;
  offDayMacros?: Macros;
  //this is deprecated start
  refeedMacros?: Macros;
  //this is deprecated end
  achivedMacros?: Macros;
  customTrackingLog?: CustomTrackingModel;
  sessionName?: string;
  eventData?: CalendarEventData;
}

export class CalendarEventData extends BaseModel {
  start?: Date;
  end?: Date;
  title?: string;
  allDay?: boolean;
  description?: string;
}

export class AppUser extends BaseModel {
  role?: UserRole;
  email?: string;
  displayName?: string;
  birthdate?: Date;
  imgUrl?: string;
  height?: number;
  startingWeight?: number;
  pending?: boolean;
  customerId?: string;

  trainingplanIds?: string[];
  traininglogIds?: string[];
  weightlogIds?: string;
  enableWeightLog?: boolean;
  enableTraining?: boolean;
  enableMacroTracking?: boolean;
  checkInWeekdayIndex?: number;
  checkInSlotIndex?: number;

  emailVerified?: boolean;
  coachId?: string;
  brandImgUrl?: string;
  clientIds?: string[];
  coachSpotId?: string;
  userDetails?: User;
  lastWeightLog?: WeightEntryData;
  setMacros?: Macros;
  registrationLinkId?: string;
  registrationDate?: Date;

  isEditing?: boolean;
  enableCTMs?: boolean;
  ctmId?: string;

  enableCMFs?: boolean;
  ccmId?: string;

  enableOffDayMacros?: boolean;
  offDayMacros?: Macros;

  enableMealPlans?: boolean;
  mealPlanId?: string;
  //this is for admin purposes only
  [key: string]: any;

  searchKey?: string;
}

export enum ClientPropertyName {
  EnableWeightLog = 'enableWeightLog',
  EnableTraining = 'enableTraining',
  EnableCTMs = 'enableCTMs',
  EnableMealPlans = 'enableMealPlans',
  EnableMacroTracking = 'enableMacroTracking',
  EnableCMFs = 'enableCMFs',
}

export interface ClientProperty {
  name: ClientPropertyName;
  description: string;
  value: boolean;
}

export enum UserRole {
  Admin = 'admin',
  Client = 'client',
  Coach = 'coach',
}

export enum RoleSelector {
  None = 'none',
  Client = 'client',
  Coach = 'coach',
}

export enum StatsMenu {
  None = 'none',
  Weightstats = 'weightstats',
  TrackingAccuracy = 'trackingAccuracy',
  CustomTrackingParameters = 'customTrackingParameters',
}

export class Message extends BaseModel {
  voiceUrl?: string;
  author?: string;
  authorId?: string;
  message?: string;
  date?: Date;
  deletedFlag?: boolean;
}

export class ClientCheckin extends BaseModel {
  clientId?: string;
  clientEmail?: string;
  clientName?: string;
  coachId?: string;
  title?: string;
  messages?: Message[];
  date?: Date;
  imgUrls?: downloadObject[];
  CustomCheckinModel?: CustomCheckinModel;
  status?: CheckinStatus;
  lastEdit?: Date;
}

export enum CheckinStatus {
  Draft = 'draft',
  Created = 'created',
  Edit = 'edit',
  Sent = 'sent',
  Answered = 'answered',
  Closed = 'closed',
}

export interface Note extends BaseModel {
  title: string;
  message: string;
  lastEdit: Date;
}

export interface CustomCheckinModel extends BaseModel {
  name: string;
  ownerId: string;
  clientIds?: string[];
  description: string;
  ccfs: CustomCheckinFormValue[];
}

export type CustomCheckinFormValue =
  | CustomCheckinFormTypeNumber
  | CustomCheckinFormTypeString
  | CustomCheckinFormTypeRange;

interface CustomCheckinFormBaseType extends BaseModel {
  type: CustomCheckinFormType;
  name: string;
  description: string;
}

export interface CustomCheckinFormTypeNumber extends CustomCheckinFormBaseType {
  slot: number;
  value: number;
  defaultValue?: number;
  type: CustomCheckinFormType.Number;
}

export interface CustomCheckinFormTypeString extends CustomCheckinFormBaseType {
  slot: number;
  type: CustomCheckinFormType.Text;
  value?: string;
}

export interface CustomCheckinFormTypeRange extends CustomCheckinFormBaseType {
  slot: number;
  type: CustomCheckinFormType.Range;
  min: number;
  max: number;
  value: number;
  step: number;
}

export enum CustomCheckinFormType {
  Text = 'text',
  Number = 'number',
  Range = 'range',
}

export interface customCheckinInputToggler {
  textInputToggle: boolean;
  numberInputToggle: boolean;
  rangeInputToggle: boolean;
}

export interface CustomTrackingModel extends BaseModel {
  name: string;
  ownerId: string;
  clientIds?: string[];
  description: string;
  logId?: string;
  ccts: CustomTrackingValue[];
}

interface CustomTrackingTypeBase extends BaseModel {
  type: CustomInputType;
  name: string;
  description: string;
}

export interface CustomTrackingTypeRange extends CustomTrackingTypeBase {
  slot: number;
  type: CustomInputType.Range;
  min: number;
  max: number;
  value: number;
  step: number;
}

export interface CustomTrackingTypeText extends CustomTrackingTypeBase {
  slot: number;
  type: CustomInputType.TextLong | CustomInputType.TextShort;
  value?: string;
}

export interface CustomTrackingTypeBoolean extends CustomTrackingTypeBase {
  slot: number;
  type: CustomInputType.Boolean;
  value?: boolean;
}

export interface CustomTrackingTypeNumber extends CustomTrackingTypeBase {
  slot: number;
  type: CustomInputType.Number;
  value?: number;
  defaultValue?: number;
}

export type CustomTrackingValue =
  | CustomTrackingTypeRange
  | CustomTrackingTypeText
  | CustomTrackingTypeBoolean
  | CustomTrackingTypeNumber;

export enum CustomInputType {
  Range = 'range',
  TextShort = 'textShort',
  TextLong = 'textLong',
  Number = 'number',
  Boolean = 'boolean',
}

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export interface contactMessage extends BaseModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
}

export interface downloadObject extends BaseModel {
  downloadURL: string;
  storagePath: string;
  formatedDate: string;
  timestamp: string;
  originalName: string;
  fileId: string;
  checkinId: string;
}

export function getWeekdayIndex(weekday: string): number {
  return weekdays.findIndex(
    (day) => weekday.toLowerCase() === day.toLowerCase(),
  );
}

export function createDateByWeekday(weekday: string) {
  if (getWeekdayIndex(weekday) === -1) {
    throw new Error('Invalid weekday input');
  }

  const currentWeekday = getDay(new Date());
  const dayToAdd = (getWeekdayIndex(weekday) - currentWeekday + 8) % 7;
  const targetDate = new Date();

  targetDate.setDate(targetDate.getDate() + dayToAdd);
  return targetDate;
}

export function calculateKcalFromMacros(macros: Macros | undefined) {
  if (
    macros &&
    (macros.protein || macros.protein === 0) &&
    (macros.carbs || macros.carbs === 0) &&
    (macros.fat || macros.fat === 0)
  ) {
    let newMacros: Macros = {};
    newMacros.protein = macros.protein;
    newMacros.carbs = macros.carbs;
    newMacros.fat = macros.fat;
    if (typeof macros.protein === 'string') {
      newMacros.protein = Number(macros.protein);
    }

    if (typeof macros.carbs === 'string') {
      newMacros.carbs = Number(macros.carbs);
    }
    if (typeof macros.fat === 'string') {
      newMacros.fat = Number(macros.fat);
    }

    return ((newMacros.protein + newMacros.carbs) * 4.1 + newMacros.fat * 9.3)
      .toFixed()
      .toString();
  }
  return '-';
}

export function convertFormToModel(
  customTrackingForm: FormGroup,
): CustomTrackingModel {
  const name = customTrackingForm.get('name')?.value as string;
  const ownerId = customTrackingForm.get('ownerId')?.value as string;
  const id = customTrackingForm.get('id')?.value as string;
  const description = customTrackingForm.get('description')?.value as string;

  const cctsArray = customTrackingForm.get('ccts') as FormArray;
  const ccts: CustomTrackingValue[] = [];

  cctsArray.controls.forEach((control) => {
    const type = control.get('type')?.value as CustomInputType;
    let ctv: CustomTrackingValue;

    switch (type) {
      case CustomInputType.Range:
        ctv = {
          type,
          name: control.get('name')?.value as string,
          description: control.get('description')?.value as string,
          slot: control.get('slot')?.value as number,
          min: control.get('min')?.value as number,
          max: control.get('max')?.value as number,
          value: control.get('value')?.value as number,
          step: control.get('step')?.value as number,
        };
        break;

      case CustomInputType.TextLong:
      case CustomInputType.TextShort:
        ctv = {
          type,
          name: control.get('name')?.value as string,
          description: control.get('description')?.value as string,
          slot: control.get('slot')?.value as number,
          value: control.get('value')?.value as string,
        };
        break;

      case CustomInputType.Number:
        ctv = {
          type,
          name: control.get('name')?.value as string,
          description: control.get('description')?.value as string,
          slot: control.get('slot')?.value as number,
          value: control.get('value')?.value as number,
          defaultValue: control.get('defaultValue')?.value as number,
        };
        break;

      case CustomInputType.Boolean:
        ctv = {
          type,
          name: control.get('name')?.value as string,
          description: control.get('description')?.value as string,
          slot: control.get('slot')?.value as number,
          value: control.get('value')?.value as boolean,
        };
        break;

      default:
        ctv = {
          type,
          name: control.get('name')?.value as string,
          description: control.get('description')?.value as string,
          slot: control.get('slot')?.value as number,
        };
        break;
    }

    ccts.push(ctv);
  });

  const customTrackingModel: CustomTrackingModel = {
    name,
    ownerId,
    id,
    description,
    ccts,
  };

  return customTrackingModel;
}

export function convertDateObject(date: Date | string): Date | undefined {
  //console.log('getting date:', date);
  if (isDate(date)) {
    //  console.log('leaving Date');
    return date as Date;
  }

  if (typeof date === 'string') {
    //  console.log('convertingString');
    // return parse(date, 'yyyy-MM-dd', new Date());

    return parseISO(date as string);
  }

  if (date instanceof firebase.firestore.Timestamp) {
    const milliseconds = date.seconds * 1000 + date.nanoseconds / 1000000;
    return new Date(milliseconds);
  }

  try {
    //  console.log('No Case found', date);
    return new Date(date);
  } catch (e) {
    console.error('ERROR at', e);
    return undefined;
  }
}

export function calcuclateDailydifferenceMaster(
  weight: number,
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
) {
  if (
    !client ||
    !weightLogData ||
    !weightLogData[index + 1] ||
    !weight ||
    !weightLogData[index + 1].weight
  )
    return '-';

  return (weight - weightLogData[index + 1].weight!).toFixed(2).toString();
}

export function calculateDifferenceFromStartMaster(
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (
    !client.startingWeight ||
    !client ||
    !weightLogData ||
    !weightLogData[index + 1] ||
    !weightLogData[index].weight! ||
    !weightLogData[weightLogData.length - 1].weight!
  )
    return '-';

  return (
    weightLogData[index].weight! -
    weightLogData[weightLogData.length - 1].weight!
  )
    .toFixed(2)
    .toString();
}

export function calculateFiveDayAverageMaster(
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (!client || !weightLogData || !weightLogData[index + 4]) return '-';

  let average = 0;

  for (let i = 0; i < 5; i++) {
    average += weightLogData[index + i].weight!;
  }
  average = average / 5;

  if (!average) return '-';

  return average.toFixed(2).toString();
}

export function calculateSevenDayAverageMaster(
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (!client || !weightLogData || !weightLogData[index + 6]) return '-';

  let average = 0;

  for (let i = 0; i < 7; i++) {
    average += weightLogData[index + i].weight!;
  }
  average = average / 7;

  if (!average) return '-';

  return average.toFixed(2).toString();
}

export enum MacroType {
  SetMacros = 'setMacros',
  RefeedMacros = 'refeedMacros',
  AchievedMacros = 'achievedMacros',
  OffdayMacros = 'offdayMacros',
}

export enum MacroSelector {
  Protein = 'protein',
  Carb = 'carb',
  Fat = 'fat',
  Kcal = 'kcal',
}

export function calculateAvgNumeric(
  index: number,
  weightlogData: WeightEntryData[],
  cctvIndex: number,
) {
  let average = 0;

  if (weightlogData[index + 6]) {
    let count = 0;

    for (let i = 0; i < 7; i++) {
      if (
        weightlogData[index + i].customTrackingLog?.ccts &&
        weightlogData[index + i].customTrackingLog?.ccts[cctvIndex]
      ) {
        let cct = weightlogData[index + i].customTrackingLog?.ccts[cctvIndex];
        if (cct) {
          {
            if (cct.type === CustomInputType.Number) {
              let convertedVal = cct.value;

              if (convertedVal !== 0 && convertedVal) {
                count++;
                average += convertedVal;
              }
            } else if (isNumber(cct.value)) {
              count++;
              average += cct.value;
            }
          }
        }
      }
    }

    average = average / count;
  } else {
    const dataLength = weightlogData.length;
    let count = 0;

    for (let i = index; i < dataLength; i++) {
      if (
        weightlogData[i].customTrackingLog?.ccts &&
        weightlogData[i].customTrackingLog?.ccts[cctvIndex]
      ) {
        let cct = weightlogData[i].customTrackingLog?.ccts[cctvIndex];
        if (cct) {
          {
            if (cct.type === CustomInputType.Number) {
              let convertedVal = cct.value;

              if (convertedVal !== 0 && convertedVal) {
                count++;
                average += convertedVal;
              }
            } else if (isNumber(cct.value)) {
              count++;
              average += cct.value;
            }
          }
        }
      }
    }
    average = average / count;
  }
  if (!average) return '-';

  if (average >= 100) {
    return average.toFixed(0).toString();
  } else {
    return average.toFixed(2).toString();
  }
}

export function calcuclateSevenDayMacro(
  index: number,
  weightLogData: WeightEntryData[],
  macroType: MacroType,
  macroSelector: MacroSelector,
) {
  if (!weightLogData) return '-';
  let average = 0;
  if (weightLogData[index + 6]) {
    let count = 0;
    for (let i = 0; i < 7; i++) {
      if (
        seletMacroType(weightLogData[index + i], macroType) &&
        selectMacro(
          seletMacroType(weightLogData[index + i], macroType) as Macros,
          macroSelector,
        )
      ) {
        count++;
        average += selectMacro(
          seletMacroType(weightLogData[index + i], macroType) as Macros,
          macroSelector,
        )!;
      }
    }

    average = average / count;
  } else {
    const dataLength = weightLogData.length;
    let count = 0;
    for (let i = index; i < dataLength; i++) {
      if (
        seletMacroType(weightLogData[i], macroType) &&
        selectMacro(
          seletMacroType(weightLogData[i], macroType) as Macros,
          macroSelector,
        )
      ) {
        count++;
        average += selectMacro(
          seletMacroType(weightLogData[i], macroType) as Macros,
          macroSelector,
        )!;
      }
    }

    average = average / count;
  }

  if (!average) return '-';

  return average.toFixed(0).toString();
}

function selectMacro(
  macros: Macros,
  macroSelector: MacroSelector,
): number | undefined {
  if (!macros) return undefined;

  switch (macroSelector) {
    case MacroSelector.Protein:
      return macros.protein;
    case MacroSelector.Carb:
      return macros.carbs;
    case MacroSelector.Fat:
      return macros.fat;
    case MacroSelector.Kcal:
      return parseFloat(calculateKcalFromMacros(macros));
    default:
      return undefined;
  }
}

function seletMacroType(
  weightLogData: WeightEntryData,
  macroType: MacroType,
): Macros | undefined {
  if (!weightLogData) return undefined;

  switch (macroType) {
    case MacroType.SetMacros:
      return weightLogData.setMacros;
    case MacroType.AchievedMacros:
      return weightLogData.achivedMacros;
    case MacroType.OffdayMacros:
      return weightLogData.offDayMacros;
    case MacroType.RefeedMacros:
      return weightLogData.refeedMacros;
    default:
      return undefined;
  }
}

export function calculateRoglDailyMaster(
  weight: number,
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (
    !client ||
    !weightLogData ||
    !weightLogData[index + 1] ||
    !weight ||
    !weightLogData[index + 1].weight
  )
    return '-';

  return (
    (100 - weightLogData[index + 1].weight! / (weight / 100))
      .toFixed(2)
      .toString() + '%'
  );
}

export function calculateRoglDailyAbsoluteMaster(
  weight: number,
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (
    !client ||
    !weightLogData ||
    !weightLogData[index + 1] ||
    !weight ||
    !weightLogData[index + 1].weight
  )
    return '-';

  return (
    (weight - weightLogData[index + 1].weight!).toFixed(2).toString() + 'kg'
  );
}

export function calculateRoglWeeklyMaster(
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (
    !client ||
    !weightLogData ||
    !weightLogData[index + 6] ||
    !weightLogData[index].weight ||
    !weightLogData[index + 6].weight
  )
    return '-';

  let sevenDayAverage: number = calculateSevenDayAverageMaster(
    index,
    client,
    weightLogData,
  ) as unknown as number;

  let lastSevenDayAverage: number = calculateSevenDayAverageMaster(
    index + 7,
    client,
    weightLogData,
  ) as unknown as number;

  if (
    sevenDayAverage.toString() === '-' ||
    lastSevenDayAverage.toString() === '-'
  ) {
    return '-';
  }

  return (sevenDayAverage - lastSevenDayAverage).toFixed(2).toString() + 'kg';
}

export function calculateRoglWeeklyPercentageMaster(
  index: number,
  client: AppUser,
  weightLogData: WeightEntryData[],
): string {
  if (
    !client ||
    !weightLogData ||
    !weightLogData[index + 6] ||
    !weightLogData[index].weight ||
    !weightLogData[index + 6].weight
  )
    return '-';

  let sevenDayAverage: number = calculateSevenDayAverageMaster(
    index,
    client,
    weightLogData,
  ) as unknown as number;

  let lastSevenDayAverage: number = calculateSevenDayAverageMaster(
    index + 7,
    client,
    weightLogData,
  ) as unknown as number;

  if (
    sevenDayAverage.toString() === '-' ||
    lastSevenDayAverage.toString() === '-'
  ) {
    return '-';
  }

  const res = 100 - lastSevenDayAverage / (sevenDayAverage / 100);

  return res.toFixed(2).toString() + '%';
}

export function isValidDate(date: any): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export function durationFromSessionMaster(td: TemplateTrainingDay) {
  let startDate = convertDateObject(td.startDate as Date);
  let endDate = convertDateObject(td.endDate as Date);

  if (!isValidDate(endDate) && startDate) {
    endDate = new Date(startDate?.getTime() + 10 * 60000);
  }

  const duration = intervalToDuration({
    start: startDate as Date,
    end: endDate as Date,
  });

  const timeBetween = {
    days: duration.days as number,
    hours: duration.hours as number,
    minutes: duration.minutes as number,
    seconds: duration.seconds as number,
  };

  if (timeBetween.days > 0) {
    return (
      timeBetween.days.toString() +
      'd ' +
      timeBetween.hours.toString() +
      'h ' +
      timeBetween.minutes.toString() +
      'min'
    );
  } else {
    return (
      timeBetween.hours.toString() +
      'h ' +
      timeBetween.minutes.toString() +
      'min'
    );
  }
}

export function getSessionsForTrainingDayMaster(
  td: TemplateTrainingDay,
  selectedMesoCycle: MesoCycle,
  selectedTrainingLog: TrainingLog,
) {
  let index = undefined;
  let sessiondates: TemplateTrainingDay[] = [];
  if (selectedMesoCycle && selectedMesoCycle.mesoname) {
    index = selectedTrainingLog?.mesoCycle?.findIndex(
      (mesoCycle) => mesoCycle.mesoname === selectedMesoCycle?.mesoname,
    );
  }

  if (index === -1) {
    return [];
  }

  if (index || index === 0) {
    selectedTrainingLog?.mesoCycle
      ?.at(index)
      ?.trainingDays?.forEach((trainingDay) => {
        if (trainingDay.trainingDayName === td.trainingDayName)
          sessiondates.push(trainingDay);
      });
  }

  sessiondates = sessiondates.sort(
    (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
  );

  return sessiondates;
}

export function getUniqueTrainingSlotsMaster(
  td: TemplateTrainingDay,
  selectedMesoCycle: MesoCycle,
  selectedTrainingLog: TrainingLog,
): TemplateTrainingSlot[] {
  let uniqueSlots: TemplateTrainingSlot[] = [];

  const sessionsForTrainingDay = getSessionsForTrainingDayMaster(
    td,
    selectedMesoCycle,
    selectedTrainingLog,
  );

  if (sessionsForTrainingDay.length > 0) {
    const index = sessionsForTrainingDay.reduce(
      (a, arr, idx) =>
        arr.trainingSlots!.length >
        sessionsForTrainingDay.at(a)!.trainingSlots!.length
          ? idx
          : a,
      0,
    );

    const sessionWithMostTrainingSlot = sessionsForTrainingDay.at(index);

    if (
      sessionWithMostTrainingSlot!.trainingSlots!.length >=
      td.trainingSlots!.length
    ) {
      uniqueSlots = sessionWithMostTrainingSlot!.trainingSlots!;
    } else {
      uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
    }
  } else {
    uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
  }

  return uniqueSlots;
}

export function deleteCheckinMessage(
  event: { checkin: ClientCheckin; msg: Message },
  checkInService: CheckinService,
) {
  //console.log(event.msg);
  //!Add here voice delete functionally because
  if (event.msg.message || event.msg.voiceUrl) {
    const index =
      event.checkin.messages?.findIndex(
        (message) => event.msg.id === message.id,
      ) ?? -1;

    if (index >= 0 && event.checkin.messages) {
      event.msg.deletedFlag = true;

      event.checkin.messages[index] = event.msg;

      checkInService.overwriteMessage(event.checkin);
    }
  }
}

export function formatUrlsWithLinks(text: string | undefined): string {
  if (text) {
    const urlPattern = /https?:\/\/\S+|www\.\S+/g;
    const urls = text.match(urlPattern);

    if (urls) {
      for (const url of urls) {
        const linkTag = url.startsWith('http')
          ? `<a class="underline text-teal-600 break-all" target="_blank"  href="${url}">${url}</a>`
          : `<a class="underline text-teal-600 break-all" target="_blank"   href="http://${url}">${url}</a>`;
        text = text.replace(url, linkTag);
      }
    }

    return text;
  } else {
    return '';
  }
}

export function formatArraysForTables(wldata: WeightEntryData[]) {
  let j = 0;
  let testarray: WeightEntryData[][] = [[]];
  //console.log(wldata)
  wldata = wldata.reverse();
  wldata.forEach((data, index) => {
    if (index === 0) {
      testarray[j].push(data);
    } else {
      const currentCustomTrackingId = data.customTrackingLog?.id;
      const previousCustomTrackingId = wldata[index - 1].customTrackingLog?.id;
      if (currentCustomTrackingId !== previousCustomTrackingId) {
        // Start a new sub-array and add the data to it
        if (currentCustomTrackingId === undefined || null) {
          testarray[j].push(data);
        } else {
          if (
            testarray[j].find(
              (item) => item.customTrackingLog?.id === currentCustomTrackingId,
            )
          ) {
            testarray[j].push(data);
          } else {
            j++;
            testarray[j] = [data];
          }
        }
      } else {
        testarray[j].push(data);
      }
    }
  });

  let result: WeightEntryData[][] = [];
  if (testarray.length === 2) {
    if (
      !testarray[0][0].customTrackingLog &&
      testarray[1][0].customTrackingLog
    ) {
      //   console.log('merginarrays')
      result.push(testarray[0].concat(testarray[1]));
    } else {
      //   console.log('notmergining')
      result = testarray;
    }
  } else {
    //  console.log('more results')
    result = testarray;
  }

  // console.log(result);

  return result;
}

export function getSessionsForLoggedTrainingDay(
  td: TemplateTrainingDay,
  loadedTrainingLogMesoCycle: MesoCycle,
) {
  let sessiondates: TemplateTrainingDay[] = [];
  loadedTrainingLogMesoCycle.trainingDays?.forEach((trainingDay) => {
    if (trainingDay.trainingDayName === td.trainingDayName)
      sessiondates.push(trainingDay);
  });

  sessiondates = sessiondates.sort(
    (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
  );

  return sessiondates;
}

export class AuthErrorConstants {
  public static authErrors: { [key: string]: string } = {
    'admin-restricted-operation':
      'This operation is restricted to administrators only.',
    'argument-error': '',
    'app-not-authorized':
      "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    'app-not-installed':
      'The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.',
    'captcha-check-failed':
      'The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.',
    'code-expired':
      'The SMS code has expired. Please re-send the verification code to try again.',
    'cordova-not-ready': 'Cordova framework is not ready.',
    'cors-unsupported': 'This browser is not supported.',
    'credential-already-in-use':
      'This credential is already associated with a different user account.',
    'custom-token-mismatch':
      'The custom token corresponds to a different audience.',
    'requires-recent-login':
      'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
    'dynamic-link-not-activated':
      'Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.',
    'email-change-needs-verification':
      'Multi-factor users must always have a verified email.',
    'email-already-in-use':
      'The email address is already in use by another account.',
    'expired-action-code': 'The action code has expired. ',
    'cancelled-popup-request':
      'This operation has been cancelled due to another conflicting popup being opened.',
    'internal-error': 'An internal error has occurred.',
    'invalid-app-credential':
      'The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.',
    'invalid-app-id':
      'The mobile app identifier is not registed for the current project.',
    'invalid-user-token':
      "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    'invalid-auth-event': 'An internal error has occurred.',
    'invalid-verification-code':
      'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.',
    'invalid-continue-uri':
      'The continue URL provided in the request is invalid.',
    'invalid-cordova-configuration':
      'The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.',
    'invalid-custom-token':
      'The custom token format is incorrect. Please check the documentation.',
    'invalid-dynamic-link-domain':
      'The provided dynamic link domain is not configured or authorized for the current project.',
    'invalid-email': 'The email address is badly formatted.',
    'invalid-api-key':
      'Your API key is invalid, please check you have copied it correctly.',
    'invalid-cert-hash': 'The SHA-1 certificate hash provided is invalid.',
    'invalid-credential':
      'The supplied auth credential is malformed or has expired.',
    'invalid-message-payload':
      'The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.',
    'invalid-multi-factor-session':
      'The request does not contain a valid proof of first factor successful sign-in.',
    'invalid-oauth-provider':
      'EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.',
    'invalid-oauth-client-id':
      'The OAuth client ID provided is either invalid or does not match the specified API key.',
    'unauthorized-domain':
      'This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.',
    'invalid-action-code':
      'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
    'wrong-password':
      'The password is invalid or the user does not have a password.',
    'invalid-persistence-type':
      'The specified persistence type is invalid. It can only be local, session or none.',
    'invalid-phone-number':
      'The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].',
    'invalid-provider-id': 'The specified provider ID is invalid.',
    'invalid-recipient-email':
      'The email corresponding to this action failed to send as the provided recipient email address is invalid.',
    'invalid-sender':
      'The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.',
    'invalid-verification-id':
      'The verification ID used to create the phone auth credential is invalid.',
    'invalid-tenant-id': "The Auth instance's tenant ID is invalid.",
    'multi-factor-info-not-found':
      'The user does not have a second factor matching the identifier provided.',
    'multi-factor-auth-required':
      'Proof of ownership of a second factor is required to complete sign-in.',
    'missing-android-pkg-name':
      'An Android Package Name must be provided if the Android App is required to be installed.',
    'auth-domain-config-required':
      'Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.',
    'missing-app-credential':
      'The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.',
    'missing-verification-code':
      'The phone auth credential was created with an empty SMS verification code.',
    'missing-continue-uri': 'A continue URL must be provided in the request.',
    'missing-iframe-start': 'An internal error has occurred.',
    'missing-ios-bundle-id':
      'An iOS Bundle ID must be provided if an App Store ID is provided.',
    'missing-multi-factor-info': 'No second factor identifier is provided.',
    'missing-multi-factor-session':
      'The request is missing proof of first factor successful sign-in.',
    'missing-or-invalid-nonce':
      'The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.',
    'missing-phone-number':
      'To send verification codes, provide a phone number for the recipient.',
    'missing-verification-id':
      'The phone auth credential was created with an empty verification ID.',
    'app-deleted': 'This instance of FirebaseApp has been deleted.',
    'account-exists-with-different-credential':
      'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
    'network-request-failed':
      'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    'no-auth-event': 'An internal error has occurred.',
    'no-such-provider':
      'User was not linked to an account with the given provider.',
    'null-user':
      'A null user object was provided as the argument for an operation which requires a non-null user object.',
    'operation-not-allowed':
      'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.',
    'operation-not-supported-in-this-environment':
      'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    'popup-blocked':
      'Unable to establish a connection with the popup. It may have been blocked by the browser.',
    'popup-closed-by-user':
      'The popup has been closed by the user before finalizing the operation.',
    'provider-already-linked':
      'User can only be linked to one identity for the given provider.',
    'quota-exceeded':
      "The project's quota for this operation has been exceeded.",
    'redirect-cancelled-by-user':
      'The redirect operation has been cancelled by the user before finalizing.',
    'redirect-operation-pending':
      'A redirect sign-in operation is already pending.',
    'rejected-credential':
      'The request contains malformed or mismatching credentials.',
    'second-factor-already-in-use':
      'The second factor is already enrolled on this account.',
    'maximum-second-factor-count-exceeded':
      'The maximum allowed number of second factors on a user has been exceeded.',
    'tenant-id-mismatch':
      "The provided tenant ID does not match the Auth instance's tenant ID",
    timeout: 'The operation has timed out.',
    'user-token-expired':
      "The user's credential is no longer valid. The user must sign in again.",
    'too-many-requests':
      'We have blocked all requests from this device due to unusual activity. Try again later.',
    'unauthorized-continue-uri':
      'The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.',
    'unsupported-first-factor':
      'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.',
    'unsupported-persistence-type':
      'The current environment does not support the specified persistence type.',
    'unsupported-tenant-operation':
      'This operation is not supported in a multi-tenant context.',
    'unverified-email': 'The operation requires a verified email.',
    'user-cancelled':
      'The user did not grant your application the permissions it requested.',
    'user-not-found':
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    'user-disabled': 'The user account has been disabled by an administrator.',
    'user-mismatch':
      'The supplied credentials do not correspond to the previously signed in user.',
    'user-signed-out': '',
    'weak-password': 'The password must be 6 characters long or more.',
    'web-storage-unsupported':
      'This browser is not supported or 3rd party cookies and data may be disabled.',
  };
}

export function patchIngredient(
  addedIngredient: AddedIngredientWithIngredient,
  enableEdit: boolean,
) {
  const fb: FormBuilder = new FormBuilder();
  return fb.group({
    id: [''],
    ingredientId: [addedIngredient.ingredientId],
    grams: [
      { value: addedIngredient.grams || 0, disabled: enableEdit },
      Validators.required,
    ],
    ingredient: fb.group({
      id: [addedIngredient.ingredient.id],
      name: [addedIngredient.ingredient.name, Validators.required],
      merchant: [addedIngredient.ingredient.merchant || ''],
      packageSize: [addedIngredient.ingredient.packageSize || 0],
      packageUnitSize: [addedIngredient.ingredient.packageUnitSize || 'g'],
      packageCost: [addedIngredient.ingredient.packageCost || 0],
      protein: [addedIngredient.ingredient.protein || 0, Validators.required],
      fat: [addedIngredient.ingredient.fat || 0, Validators.required],
      saturatedFat: [addedIngredient.ingredient.saturatedFat || 0],
      carb: [addedIngredient.ingredient.carb || 0, Validators.required],
      sugar: [addedIngredient.ingredient.sugar || 0],
      fibre: [addedIngredient.ingredient.fibre || 0],
    }),
  });
}

export function calculateAgeMaster(birthday: Date) {
  if (birthday) {
    const age = differenceInYears(new Date(), birthday);
    return age;
  }
  return null;
}
//New Trainingplan model

//ToDOs:
/*
1) Create Modelgenerator
    - Create Input Types

2) Create Trainingplan Generator
    - Create TrainingSlot
    - Create TrainingDay
    - Create Mesocycle
    - Create TrainingPlan

*/
export interface SlotModel extends BaseModel {
  name: string; //ModelName
  ownerId?: string;
  customInput: CustomSlotModelInput;
}

export interface CustomSlotModelInput extends BaseModel {
  value?: number; //Sets, Runs, Trys
  csmiv: CustomSlotModelInputValue[];
  progressDirection: boolean; //true if lower values are better, false if higher values are better
}

export type CustomSlotModelInputValue =
  | CustomSlotInputNumber
  //| CustomSlotInputText
  | CustomSlotInputTime
  | CustomSlotInputTarget;

export enum CustomSlotModelInputType {
  //Text = 'text',
  Number = 'number',
  Time = 'time',
  Target = 'target',
}

export interface CustomSlotInputNumber extends BaseModel {
  value?: number;
  coachValue?: string;
  name: string;
  description?: string;
  includeCalc: boolean;
  type: CustomSlotModelInputType.Number;
  slot: number;
}

/*
export interface CustomSlotInputText extends BaseModel {
  value?: string;
  name: string;
  description?: string;
  type: CustomSlotModelInputType.Text;
  slot: number;
}*/
export interface CustomSlotInputTarget extends BaseModel {
  value?: string;
  name: string;
  description?: string;
  type: CustomSlotModelInputType.Target;
  slot: number;
}

export interface CustomSlotInputTime extends BaseModel {
  value?: Timestamp; //Stored as Seconds and Nanoseconds
  coachValue?: Timestamp;
  includeCalc: boolean;
  name: string;
  description?: string;
  type: CustomSlotModelInputType.Time;
  slot: number;
}
export interface InputSetV2 extends BaseModel {
  data: CustomSlotModelInputValue;
  modelName: string;
  modelId: string;
  slotNr: number; //Set, try, run
  progressDirection: boolean;
}

export interface CoachFeedback extends BaseModel {
  id: string;
  feedback: Message;
}

export interface TemplateTrainingSlotV2 extends BaseModel {
  date: Date;
  slotNr: number;
  slotModel: SlotModel;

  exercise: Exercise;
  exerciseSetup: string;

  comment: string;
  inputSets: InputSetV2[];

  coachFeedback: CoachFeedback;
}

export interface TemplateTrainingDayV2 extends BaseModel {
  trainingDayId: string;
  trainingPlanId: string;
  trainingDayName: string;

  startDate: Date;
  endDate: Date;

  sessionRating: number;

  trainingSlots: TemplateTrainingSlotV2[];
}

export interface MesoCycleV2 extends BaseModel {
  mesoName: string;
  trainingDays: TemplateTrainingDayV2[];
}

export interface TrainingPlanV2 extends BaseModel {
  trainingPlanName: string;
  trainingPlanDescription: string;

  clientId: string;
  ownerId: string;

  lastEdit: Date;
  meosCycles: MesoCycleV2[];
}

export function isModelInputType(checkType: CustomSlotModelInputType) {
  const validInputTypes = [
    CustomSlotModelInputType.Number,
    CustomSlotModelInputType.Time,
  ];

  return validInputTypes.includes(checkType);
}
export function atLeastOneValidation(control: AbstractControl, name: string) {
  const daysArray = control.get(name) as FormArray;

  if (daysArray && daysArray.length > 0) {
    return null;
  } else {
    return { atLeastOne: true };
  }
}
