import { CustomInputType, CustomTrackingValue } from './../../core/thecoach';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CustomTrackingModel } from 'src/app/core/thecoach';

@Component({
  selector: 'app-custom-model-modal',
  template: `
    <div
      @opacity
      *ngIf="isModal"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
        >
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div
            @opacityTranslateY
            *ngIf="isModal"
            class="relative transform max-w-11/12 p-4 rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  sm:max-w-lg"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="flex flex-col items-center">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <img
                    class="w-6 h-6"
                    src="../../assets/icons/3d-modeling-icon.png"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Tracking Parameters
                  </h3>
                </div>
                <form
                  *ngIf="customTrackingForm && cctsArray.length > 0"
                  [formGroup]="customTrackingForm"
                >
                  <div
                    *ngFor="let cct of cctsArray; let i = index"
                    class="py-2"
                  >
                    <div formArrayName="ccts">
                      <ng-container [ngSwitch]="cct.get('type')!.value">
                        <div *ngSwitchCase="customInputType.Range" class="py-2">
                          <app-generated-range-slider
                            [formGroupName]="i"
                            [cctv]="cct"
                          ></app-generated-range-slider>
                        </div>
                        <div *ngSwitchCase="customInputType.TextShort">
                          <app-generated-text-input
                            [formGroupName]="i"
                            [cctv]="cct"
                          ></app-generated-text-input>
                        </div>
                        <div *ngSwitchCase="customInputType.TextLong">
                          <app-generated-text-input
                            [formGroupName]="i"
                            [cctv]="cct"
                          ></app-generated-text-input>
                        </div>
                        <div *ngSwitchCase="customInputType.Number">
                          <app-generated-number-input
                            [formGroupName]="i"
                            [cctv]="cct"
                          ></app-generated-number-input>
                        </div>

                        <div *ngSwitchCase="customInputType.Boolean">
                          <app-generated-boolean-input
                            [formGroupName]="i"
                            [cctv]="cct"
                          ></app-generated-boolean-input>
                        </div>
                        <div *ngSwitchDefault>NO TYPE FOUND</div>
                      </ng-container>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 flex flex-row justify-between">
              <button
                type="button"
                (click)="onSendValue(true)"
                class="inline-flex w-fit justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto"
              >
                Save
              </button>
              <button
                type="button"
                (click)="onSendValue(false)"
                class="inline-flex w-fit  justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
          '200ms ease-in',
          style({ opacity: 0, transform: 'translateY(1rem)' }),
        ),
      ]),
    ]),
  ],
})
export class CustomModelModalComponent implements OnInit {
  @Output() close = new EventEmitter<{
    event: boolean;
    form: FormGroup | undefined;
  }>();
  @Input() ctm: CustomTrackingModel | undefined;

  isModal = true;
  customInputType = CustomInputType;

  customTrackingForm: FormGroup | undefined;
  run = true;
  i = 0;

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    if (this.ctm) {
      this.initForm(this.ctm);
      this.generateForm(this.ctm);
    }
  }

  onSendValue(value: boolean) {
    this.close.emit({ event: value, form: this.customTrackingForm });
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  initForm(ctm: CustomTrackingModel) {
    this.customTrackingForm = this.fb.group({
      name: ctm.name,
      id: ctm.id,
      logid: ctm.logId,
      description: ctm.description,
      ownerId: ctm.ownerId,
      ccts: this.fb.array([]),
    });
  }

  get cctsArray() {
    const formArray = this.customTrackingForm?.get('ccts') as FormArray;
    return formArray.controls as FormGroup[];
  }

  generateForm(ctm: CustomTrackingModel) {
    this.ctm!.ccts.forEach((ctv) => {
      let customFormGroup: FormGroup | null = null;
      switch (ctv.type) {
        case CustomInputType.Range:
          customFormGroup = this.fb.group({
            type: [ctv.type],
            name: [ctv.name],

            description: [ctv.description],
            slot: [ctv.slot],
            id: [ctv.id],
            value: [ctv.value],
            min: [ctv.min],
            max: [ctv.max],
            step: [ctv.step],
          });

          break;
        case CustomInputType.TextLong:
          customFormGroup = this.fb.group({
            type: [ctv.type],
            name: [ctv.name],

            description: [ctv.description],
            slot: [ctv.slot],
            id: [ctv.id],
            value: [ctv.value],
          });

          break;
        case CustomInputType.TextShort:
          customFormGroup = this.fb.group({
            type: [ctv.type],
            name: [ctv.name],

            description: [ctv.description],
            slot: [ctv.slot],
            id: [ctv.id],
            value: [ctv.value],
          });
          break;

        case CustomInputType.Boolean:
          customFormGroup = this.fb.group({
            type: [ctv.type],
            name: [ctv.name],

            description: [ctv.description],
            slot: [ctv.slot],
            id: [ctv.id],
            value: [ctv.value],
          });

          break;
        case CustomInputType.Number:
          customFormGroup = this.fb.group({
            type: [ctv.type],
            name: [ctv.name],
            defaultValue: [ctv.defaultValue],
            description: [ctv.description],
            slot: [ctv.slot],
            id: [ctv.id],
            value: [ctv.value],
          });

          break;
        default:
          break;
      }

      if (customFormGroup) {
        this.cctsArray.push(customFormGroup);
      }
    });
  }

  log(val: any) {
    if (this.run && this.i < 6) {
      console.log('HTML TEST', val);
      this.i++;
    }
  }
}
