import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Subscription, catchError, finalize, first, of } from 'rxjs';
import {
  AppUser,
  MesoCycle,
  TemplateTrainingDay,
  TemplateTrainingSlot,
  TrainingLog,
  TrainingPlan,
  convertDateObject,
  durationFromSessionMaster,
} from 'src/app/core/thecoach';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-training-log-panel-dashboard',
  template: `
    <div
      class="overflow-x-auto shadow-md rounded-lg bg-white  w-full h-fit ring-1 ring-gray-300"
    >
      <div class="pl-4 pt-2 flex flex-row w-full items-center">
        <div class=" " *ngIf="loadedTrainingLog !== null; else loading">
          <button
            class="text-gray-400 border-2 border-gray-400 rotate-90 bg-white hover:bg-gray-200 text-white font-bold py-2 px-1 rounded flex items-center"
            (click)="enableViewDropdown()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 stroke-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </button>
          <ul
            *ngIf="viewSettings"
            class="absolute  z-10 mt-1 max-h-60 w-fit  rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            id="options"
            role="listbox"
          >
            <ng-container
              *ngIf="
                this.loadedTrainingLog?.advancedView !== undefined;
                else noViewSelected
              "
            >
              <li
                class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900  hover:bg-gray-200 "
                id="option-0"
                role="option"
                (click)="setAdvancedView(this.loadedTrainingLog, true)"
              >
                <!-- Selected: "font-semibold" -->
                <span class="block truncate">Advanced View</span>

                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-teal-600"
                  *ngIf="this.loadedTrainingLog.advancedView === true"
                >
                  <!-- Heroicon name: mini/check -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
              <li
                class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900  hover:bg-gray-200 "
                id="option-1"
                role="option"
                (click)="setAdvancedView(this.loadedTrainingLog, false)"
              >
                <!-- Selected: "font-semibold" -->
                <span class="block truncate">Simple View</span>
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-teal-600"
                  *ngIf="this.loadedTrainingLog.advancedView === false"
                >
                  <!-- Heroicon name: mini/check -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </li>
            </ng-container>
            <ng-template #noViewSelected>
              <li
                class="relative  select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-gray-200 cursor-pointer"
                id="option-0"
                role="option"
                (click)="setAdvancedView(this.loadedTrainingLog, true)"
              >
                <!-- Selected: "font-semibold" -->
                <span class="block truncate">Advanced View</span>
              </li>
              <li
                class="relative select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-gray-200 cursor-pointer"
                id="option-1"
                role="option"
                (click)="setAdvancedView(this.loadedTrainingLog, false)"
              >
                <!-- Selected: "font-semibold" -->
                <span class="block truncate">Simple View</span>
              </li>
            </ng-template>

            <!-- More items... -->
          </ul>
          <div
            *ngIf="viewSettings"
            (click)="enableViewDropdown()"
            class="fixed inset-0"
          ></div>
        </div>

        <!-- TRAININGPLAN-->
        <div
          class="pl-6"
          *ngIf="
            this.selectedClient.traininglogIds &&
            this.selectedClient.traininglogIds.length > 1
          "
        >
          <div class="relative inline-block text-left">
            <div>
              <button
                type="button"
                (click)="togglePlanSelect()"
                class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                {{
                  getTrainingPlanFromLog(loadedTrainingLog)?.trainingPlanName
                }}
                <svg
                  class="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
            <div
              @opacityScale
              *ngIf="isTrainingPlanSelect"
              class="absolute left-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <div
                  (click)="
                    setLoadedTrainingLog(trainingLogs.get(trainingLogKey))
                  "
                  *ngFor="let trainingLogKey of trainingLogKeys"
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  {{
                    getTrainingPlanFromLog(trainingLogs.get(trainingLogKey))
                      ?.trainingPlanName
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="isTrainingPlanMenu"
            (click)="toggleTrainingPlanMenu()"
            class="fixed inset-0"
          ></div>
        </div>
        <!-- MESO -->
        <div
          class="pl-6"
          *ngIf="
            loadedTrainingLogMesoCycles.length > 1 && loadedTrainingLogMesoCycle
          "
        >
          <div class="relative inline-block text-left">
            <div>
              <button
                type="button"
                (click)="toggleMesoSelect()"
                class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                {{ loadedTrainingLogMesoCycle.mesoname }}
                <svg
                  class="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <!--
Dropdown menu, show/hide based on menu state.

Entering: "transition ease-out duration-100"
From: "transform opacity-0 scale-95"
To: "transform opacity-100 scale-100"
Leaving: "transition ease-in duration-75"
From: "transform opacity-100 scale-100"
To: "transform opacity-0 scale-95"
-->
            <div
              @opacityScale
              *ngIf="isMesoSelect"
              class="absolute left-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <div
                  (click)="setLoadedTrainingLogMesocycle(meso)"
                  *ngFor="let meso of loadedTrainingLogMesoCycles"
                  class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="menu-item-0"
                >
                  {{ meso.mesoname }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="isMenu"
            (click)="toggleMenu()"
            class="fixed inset-0"
          ></div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:pb-6">
        <div
          *ngFor="
            let trainingday of this.loadedTrainingPlanMesoCycle?.trainingDays
          "
          class=""
        >
          <h3 class="italic font-semibold text-gray-900 text-sm mt-2">
            Trainingday: {{ trainingday.trainingDayName }}
          </h3>
          <table class="divide-y divide-gray-300 w-fit table-fixed border-2  ">
            <thead>
              <tr class="divide-x divide-gray-200">
                <th
                  scope="col"
                  class="py-2 px-0.5 text-left text-sm font-semibold text-gray-900 w-[2rem]"
                >
                  Slot
                </th>
                <th
                  scope="col"
                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[9rem]"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[2rem]"
                >
                  <div class=" group flex relative items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
                                  -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                      >Exercise Description</span
                    >
                  </div>
                </th>
                <th
                  scope="col"
                  class="py-2 px-0.5 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                >
                  Sets
                </th>
                <th
                  scope="col"
                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[3.5rem]"
                >
                  Reps
                </th>
                <th
                  scope="col"
                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                >
                  RIR
                </th>
                <ng-container
                  *ngIf="getSessionsForTrainingDay(trainingday).length > 0"
                >
                  <th
                    class="py-2 px-0.5 text-center text-sm font-semibold text-gray-900 whitespace-nowrap	w-[12rem] "
                    *ngFor="
                      let session of getSessionsForTrainingDay(trainingday)
                    "
                  >
                    <p
                      *ngIf="
                        session.startDate && session.endDate;
                        else startdate
                      "
                    >
                      {{ session.startDate | date: 'dd-MM' }} -
                      {{ durationFromSession(session) }}
                    </p>

                    <ng-template #startdate>
                      {{ session.startDate | date: 'dd-MM HH:mm' }}
                    </ng-template>

                    <p
                      *ngIf="session.sessionRating"
                      class="italic text-xs font-normal text-gray-600"
                    >
                      Rating: {{ session.sessionRating }}/10
                    </p>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                class="divide-x divide-gray-200"
                *ngFor="
                  let trainingSlot of getUniqueTrainingSlots(trainingday);
                  let j = index
                "
              >
                <td
                  class="whitespace-normal py-2 px-2 text-sm font-medium text-gray-900 text-center truncate"
                >
                  {{ trainingSlot.slot }}
                </td>
                <td
                  class="whitespace-nowrap p-2 text-sm text-gray-500 truncate ..."
                >
                  {{ trainingSlot.exerciseName }}
                </td>
                <td
                  class="p-2 text-sm text-gray-500 flex flex-row  items-center justify-center"
                >
                  <div class=" group flex relative items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
                                  -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                      >{{ trainingSlot.exerciseDescription }}</span
                    >
                  </div>
                </td>
                <td
                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.sets }}
                </td>
                <td
                  class="whitespace-nowrap py-2 text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.reps }}
                </td>
                <td
                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.rir }}
                </td>
                <ng-container>
                  <td
                    *ngFor="
                      let slot of getTrainingSlotSessionData(
                        trainingday,
                        trainingSlot
                      );
                      let i = index
                    "
                    class="whitespace-nowrap py-2 items-center px-1 text-sm text-gray-500 text-center"
                  >
                    <ng-container
                      *ngIf="
                        loadedTrainingPlanMesoCycle &&
                        loadedTrainingLogMesoCycle &&
                        loadedTrainingLog
                      "
                    >
                      <ng-container
                        *ngIf="
                          this.loadedTrainingLog?.advancedView &&
                            this.loadedTrainingLog?.advancedView === true;
                          else simpleView
                        "
                      >
                        <app-training-log-slot-detail-view
                          [slot]="slot"
                          [trainingday]="trainingday"
                          [i]="i"
                          [selectedMesoCycle]="this.loadedTrainingLogMesoCycle"
                          [selectedTrainingLog]="this.loadedTrainingLog"
                        ></app-training-log-slot-detail-view>
                      </ng-container>
                      <ng-template #simpleView>
                        <app-training-log-slot-detail-simple-view
                          [slot]="slot"
                          [trainingday]="trainingday"
                          [i]="i"
                          [selectedMesoCycle]="this.loadedTrainingLogMesoCycle"
                          [selectedTrainingLog]="this.loadedTrainingLog"
                        >
                        </app-training-log-slot-detail-simple-view>
                      </ng-template>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>
    <div *ngIf="savingToggler">
      <app-loader-modal [loadingText]="'Loading Clientdata'"></app-loader-modal>
    </div>
  `,
  styles: [],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class TrainingLogPanelDashboardComponent
  implements OnInit, OnDestroy, OnChanges
{
  mesoCycleSubscription: Subscription | undefined;
  trainingLogSubscription: Subscription | undefined;
  //trainingLogsSubscription: Subscription | undefined;
  trainingplanSubscriptions: Subscription | undefined;
  //  trainingPlansSubscription: Subscription | undefined;
  @Input() selectedClient!: AppUser;

  isMenu = false;
  isTrainingPlanMenu = false;
  loadedTrainingPlanMesoCycle: MesoCycle | null = null;
  loadedTrainingLogMesoCycle: MesoCycle | null = null;
  loadedTrainingLog: TrainingLog | null = null;
  loadedTrainingPlan: TrainingPlan | null = null;
  //  trainingLogs: TrainingLog[] = [];
  //trainingPlans: TrainingPlan[] = [];
  //

  viewSettings = false;

  isMesoSelect = false;
  isTrainingPlanSelect = false;

  loadedTrainingLogMesoCycles: MesoCycle[] = [];

  trainingPlans = new Map<string, TrainingPlan>();
  trainingLogs = new Map<string, TrainingLog>();

  savingToggler = false;

  constructor(
    private trainingLogService: LogService,
    private trainingPlanService: TrainingplanService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedClient']) {
      const change: SimpleChange = changes['selectedClient'];
      //console.log('Previous value:', change.previousValue);
      // console.log('Current value:', change.currentValue);

      // Example: Check if a specific field has changed
      if (
        change.previousValue !== undefined &&
        change.previousValue?.id !== change.currentValue?.id
      ) {
        // console.log('The User Changed!');
        this.ngOnDestroy();
        this.ngOnInit();
      }

      // Do other logic here based on changes
    }
  }
  ngOnInit(): void {
    this.toggleSaving();
    this.resetVariables();
    this.getTrainingPlan(this.selectedClient);
    this.getTrainingLogs(this.selectedClient);
    this.getAllTrainingPlans(this.selectedClient);
    this.getAllTrainingLogs(this.selectedClient);
    //  console.log(this.selectedClient);
  }

  setLoadedTrainingLogMesocycle(meso: MesoCycle) {
    this.toggleSaving();

    //small Timeou for loading toggler, 500ms for loading the animation
    setTimeout(() => {
      this.loadedTrainingLogMesoCycle = meso;
      this.toggleSaving();
      this.toggleMesoSelect();
    }, 500);
  }

  toggleMesoSelect() {
    this.isMesoSelect = !this.isMesoSelect;
  }
  togglePlanSelect() {
    this.isTrainingPlanSelect = !this.isTrainingPlanSelect;
  }
  toggleSaving() {
    this.savingToggler = !this.savingToggler;
  }

  setLoadedTrainingLog(trainingLog: TrainingLog | undefined) {
    if (trainingLog) {
      this.loadedTrainingLog = trainingLog;
      this.getLatestMesoFromLoadedLog(trainingLog);

      if (trainingLog.trainingPlanId) {
        const plan = this.trainingPlans.get(trainingLog.trainingPlanId);
        if (plan && plan.mesoCycles) {
          const lastMesoIndex = plan.mesoCycles.length - 1;
          this.loadedTrainingPlanMesoCycle = plan?.mesoCycles[lastMesoIndex];
        }
      }
    }

    if (this.isTrainingPlanSelect) {
      this.togglePlanSelect();
    }
  }

  getTrainingPlanFromLog(
    trainingLog: TrainingLog | null | undefined,
  ): TrainingPlan | null {
    if (trainingLog && trainingLog.trainingPlanId) {
      let loadedTP = this.trainingPlans.get(
        trainingLog.trainingPlanId,
      ) as TrainingPlan;

      this.loadedTrainingPlan = loadedTP;
      return loadedTP;
    } else {
      return null;
    }
  }

  getLatestMesoFromLoadedLog(trainingLog: TrainingLog) {
    let sortedMesos: MesoCycle[] = [];

    if (trainingLog.mesoCycle && trainingLog.mesoCycle?.length > 1) {
      sortedMesos = trainingLog.mesoCycle?.sort(
        (a, b) =>
          b.trainingDays![b.trainingDays!.length - 1].startDate!.getTime() -
          a.trainingDays![a.trainingDays!.length - 1].startDate!.getTime(),
      );
    } else if (trainingLog.mesoCycle) {
      sortedMesos = trainingLog.mesoCycle;
    } else {
      console.error('NO MESO FOUND');
    }

    //console.log('sortedMesos',sortedMesos);

    this.loadedTrainingLogMesoCycle = sortedMesos[0];
    this.loadedTrainingLogMesoCycles = sortedMesos;

    // console.log(sortedMesos![0]!.trainingDays![0]!);

    if (
      this.loadedTrainingLogMesoCycle &&
      this.loadedTrainingPlanMesoCycle &&
      this.loadedTrainingPlanMesoCycle.trainingDays
    ) {
      const sessionLimit =
        this.loadedTrainingPlanMesoCycle.trainingDays.length * 5;

      if (sessionLimit < 20) {
        this.loadedTrainingLogMesoCycle.trainingDays =
          this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, sessionLimit);
      } else {
        this.loadedTrainingLogMesoCycle.trainingDays =
          this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
      }
    } else if (this.loadedTrainingLogMesoCycle) {
      this.loadedTrainingLogMesoCycle.trainingDays =
        this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
    }
  }

  getLatestMesoCycle(client: AppUser) {
    this.mesoCycleSubscription = this.trainingLogService
      .getLatestMesoCycleForUser(
        client.id!,
        client.traininglogIds?.at(client.traininglogIds.length - 1) as string,
      )
      .subscribe((mesoCycle) => {
        //console.log('meso', mesoCycle)
        this.loadedTrainingLogMesoCycle = mesoCycle;

        this.loadedTrainingLogMesoCycle!.trainingDays =
          this.loadedTrainingLogMesoCycle?.trainingDays?.sort(
            (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
          );
        if (
          this.loadedTrainingLogMesoCycle &&
          this.loadedTrainingPlanMesoCycle &&
          this.loadedTrainingPlanMesoCycle.trainingDays
        ) {
          const sessionLimit =
            this.loadedTrainingPlanMesoCycle.trainingDays.length * 5;

          if (sessionLimit < 20) {
            this.loadedTrainingLogMesoCycle.trainingDays =
              this.loadedTrainingLogMesoCycle?.trainingDays?.slice(
                0,
                sessionLimit,
              );
          } else {
            this.loadedTrainingLogMesoCycle.trainingDays =
              this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
          }
        } else if (this.loadedTrainingLogMesoCycle) {
          this.loadedTrainingLogMesoCycle.trainingDays =
            this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
        }

        // console.log('loaded Training Log MesoCycle',this.loadedTrainingLogMesoCycle)
        //console.log('loaded Training Plan MesoCycle',this.loadedTrainingPlanMesoCycle)
      });
  }

  getTrainingLogs(appUser: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getTrainingLogForUserLatest(appUser.id as string)
      .pipe(first())
      .subscribe((vals) => {
        if (vals.length > 1) {
          vals = this.sortTrainingLogsToLatest(vals);
        }
        this.loadedTrainingLog = vals[0];

        // console.log('loadedLog',this.loadedTrainingLog, vals)
        this.getLatestMesoFromLoadedLog(this.loadedTrainingLog);
      });
  }

  sortTrainingLogsToLatest(trainingLogs: TrainingLog[]) {
    return trainingLogs.sort((a, b) => {
      const mostRecentDateA = this.getMostRecentData(a);
      const mostRecentDateB = this.getMostRecentData(b);

      return mostRecentDateB! - mostRecentDateA!;
    });
  }

  getMostRecentData(trainingLog: TrainingLog) {
    let trainingdate: Date | undefined = undefined;

    trainingLog.mesoCycle?.forEach((meso) => {
      meso.trainingDays?.forEach((td) => {
        td.startDate = convertDateObject(td.startDate as Date);

        if (trainingdate === undefined) {
          trainingdate = td.startDate;
        } else {
          if (trainingdate <= td.startDate!) {
            trainingdate = td.startDate;
          }
        }
      });
    });

    return trainingdate;
  }

  resetVariables() {
    this.trainingLogs.clear();
    this.trainingPlans.clear();
    this.loadedTrainingLog = null;
    this.loadedTrainingLogMesoCycle = null;
    this.loadedTrainingPlanMesoCycle = null;
  }
  ngOnDestroy(): void {
    this.mesoCycleSubscription?.unsubscribe();
    this.trainingLogSubscription?.unsubscribe();
    this.trainingplanSubscriptions?.unsubscribe();
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }
  toggleTrainingPlanMenu() {
    this.isTrainingPlanMenu = !this.isTrainingPlanMenu;
  }

  getAllTrainingLogs(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getTrainingLogsForUser(client.id as string)
      .pipe(
        first(),
        catchError((error) => {
          console.error('Error fetching training logs:', error);
          if (this.savingToggler) {
            this.toggleSaving();
          }
          return of([]); // Return an empty array or handle the error as needed
        }),
        finalize(() => {
          if (this.savingToggler) {
            this.toggleSaving();
          }
          //          console.log('Data retrieval complete or error encountered');
        }),
      )
      .subscribe((tls: TrainingLog[]) => {
        tls.forEach((tl) => {
          if (tl.id) {
            this.trainingLogs.set(tl.id, tl);
          }
        });
        //      console.log(this.trainingLogs);
      });
  }
  getTrainingLog(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getLatestLogForUser(
        client.id!,
        client.traininglogIds?.at(client.traininglogIds.length - 1) as string,
      )
      .subscribe((val) => {
        if (val) {
          this.loadedTrainingLog = val;
        }
      });
  }

  getAllTrainingPlans(client: AppUser) {
    this.trainingPlanService
      .getAllTrainingplansForClient(client)
      .pipe(first())
      .subscribe((tps) => {
        tps.forEach((tp) => {
          if (tp.id) {
            this.trainingPlans.set(tp.id, tp);
          }
        });
        //  console.log(this.trainingPlans);
      });
  }

  getTrainingPlan(client: AppUser) {
    this.trainingplanSubscriptions = this.trainingPlanService
      .getTrainingPlan(
        client.trainingplanIds![client.trainingplanIds!.length - 1],
        client,
      )
      .subscribe((plan) => {
        if (plan && plan.mesoCycles) {
          const lastMesoIndex = plan.mesoCycles.length - 1;
          this.loadedTrainingPlanMesoCycle = plan?.mesoCycles[lastMesoIndex];
        }
      });
  }

  /*
  getTrainingLogName(logId: string | undefined) {
    if (logId) {
      return this.trainingPlans.find(plan => plan.id === logId)?.trainingPlanName
    }
    return null
  }
*/

  getSessionsForTrainingDay(td: TemplateTrainingDay) {
    let sessiondates: TemplateTrainingDay[] = [];
    this.loadedTrainingLogMesoCycle?.trainingDays?.forEach((trainingDay) => {
      if (
        td.trainingDayId &&
        trainingDay.trainingDayId &&
        trainingDay.trainingDayId === td.trainingDayId
      ) {
        sessiondates.push(trainingDay);
      } else if (trainingDay.trainingDayName === td.trainingDayName) {
        sessiondates.push(trainingDay);
      }
    });

    sessiondates = sessiondates.sort(
      (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
    );

    return sessiondates;
  }

  durationFromSession(td: TemplateTrainingDay) {
    return durationFromSessionMaster(td);
  }

  getUniqueTrainingSlots(td: TemplateTrainingDay): TemplateTrainingSlot[] {
    let uniqueSlots: TemplateTrainingSlot[] = [];

    const sessionsForTrainingDay = this.getSessionsForTrainingDay(td);

    if (sessionsForTrainingDay.length > 0) {
      const index = sessionsForTrainingDay.reduce(
        (a, arr, idx) =>
          arr.trainingSlots!.length >
          sessionsForTrainingDay.at(a)!.trainingSlots!.length
            ? idx
            : a,
        0,
      );

      const sessionWithMostTrainingSlot = sessionsForTrainingDay.at(index);

      if (
        sessionWithMostTrainingSlot!.trainingSlots!.length >=
        td.trainingSlots!.length
      ) {
        uniqueSlots = sessionWithMostTrainingSlot!.trainingSlots!;
      } else {
        uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
      }
    } else {
      uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
    }

    return uniqueSlots;
  }

  getTrainingSlotSessionData(
    td: TemplateTrainingDay,
    slot: TemplateTrainingSlot,
  ) {
    let sessionslots: TemplateTrainingSlot[] = [];

    const sessiondates = this.getSessionsForTrainingDay(td);

    sessiondates.forEach((session) => {
      session.trainingSlots?.forEach((sessionslot) => {
        if (
          sessionslot.exerciseId === slot.exerciseId &&
          sessionslot.slot == slot.slot
        )
          sessionslots.push(sessionslot);
      });
    });

    return sessionslots;
  }

  enableViewDropdown() {
    this.viewSettings = !this.viewSettings;
  }

  setAdvancedView(log: TrainingLog, value: boolean) {
    this.toggleSaving();

    if (log.advancedView && log.advancedView === value) {
      this.toggleSaving();
    } else {
      log.advancedView = value;
      this.trainingLogService
        .updateLog(log)
        .then(() => {
          this.toggleSaving();
        })
        .catch((error) => {
          this.toggleSaving();
        });
    }
    this.enableViewDropdown();
  }

  setNewTrainingLog(log: TrainingLog) {
    this.loadedTrainingLog = log;
  }

  get trainingLogKeys(): string[] {
    return Array.from(this.trainingLogs.keys());
  }
}
